<template>

	<div class="cols-2 gap-big pos-relative ">
		 <div class="cols-1 gap-small ">
		  <a v-if="options?.link" @click="clickBuyTicket()" href="#"  class="bg-main flex-child-grow-1  flex-child-shrink-0 flex-child-basis-auto o-hidden  radius-big pd-big flex-center flex-column flex w-100 gap-zero">
		  	<img loading="lazy" v-if="content?.ticket" class="w-80" :src="content?.ticket">

	    	<a class="t-white bg-black pd-l-small pd-b-thin pd-t-thin pd-r-small h4 radius-extra uppercase t-nye204">
	    		<!-- SOLD OUT -->
					{{ content?.ticketTextButton || 'Buy Ticket'}}
		    </a>
		    
		  </a>

		  <p v-if="content?.paymentContact" class="pd-small radius-big bg-second flex flex-center gap-small p-medium t-medium t-white">
	    	<span>{{content?.paymentText}}</span> 
  			<a class="t-black bg-white radius-extra pd-thin" :href="content?.paymentContactURL" target="_blank">{{content?.paymentContact}}</a>
  		</p>
  	</div>

		<div class="br-solid br-1px br-white-transp-10 o-hidden radius-big bg-black ">

			<video 
				v-if="options?.video" style="max-height: 20rem; object-fit: cover;" 
				ref="videoElement"  class="pos-t-0 pos-l-0 w-100 h-auto" 
				preload autoplay muted loop playsinlineclass playsinline 
				:src="options?.video" itemprop="video" type="video/mp4"
			>
			</video>


	    <div class="pd-big flex-column flex w-100 h-100 gap-zero">
	    	<h3  v-if="content?.previousTitle" class="t-unna t-nye204 mn-b-small">
					{{content?.previousTitle}}
		    </h3>
		    
		    <p  v-if="content?.previousDescription" class="p-medium t-transp mn-b-big">
		    	{{content?.previousDescription}}
		    </p>

		    <div v-if="content?.previousLink" class="flex-nowrap flex gap-thin">
		    	<a :href="content?.previousLink" 	target="_blank" class="pd-l-medium pd-r-medium pd-t-small pd-b-small t-black radius-extra uppercase t-medium bg-white">
		    		{{content?.previousLinkText || 'Check'}}
		    	</a>
		    </div>
	    </div>
	    
	  </div>

	 
	</div>
</template>


<script setup>
	import { ref, onMounted } from 'vue'
	import { useI18n } from 'vue-i18n'


	const props = defineProps({
		content: {
			type: Object
		},
		options: {
			type: Object
		}
	})


	
	const { t } = useI18n({ 
		messages: {
		  en:{
		    "nye2024":{
		      "title":"Explore our exclusive party archive",
		      "titleTicket":"Are you ready?",
		      "description":"Experience the ultimate party vibe at The Commune! Elevate your evening with distinctive and exhilarating activities, ensuring an unforgettable time. Dive into the excitement and make every moment special.",
		      "checkinblog":"CHECK IN INSTAGRAM",
		    }
		  }
		}
	})

	const videoElement = ref(null)

	var isPlaying = false;

	function checkAndPlayVideo() {
		if (videoElement.value) videoElement.value.onplaying = function() { isPlaying = true; };
		if (videoElement.value) videoElement.value.onpause = function() { isPlaying = false; };	
  }

  // Play video function
	async function playVid() {      
    if (videoElement.value?.paused && !isPlaying) {
      return videoElement.value.play();
    }
	} 

	function clickBuyTicket () {
		gtag('event', 'buy_ticket', {
	    'event_category': 'conversion',
	    'event_label': 'Buy Ticket'
	  });

	  window.open(props.options?.link);

	}
  
	onMounted(() => {
		checkAndPlayVideo()
		playVid()
	})
</script>

<style lang="scss">
</style>
