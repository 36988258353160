<template>
  <article 
    class="pd-thin"
>
    <section  
      style="min-height: 100%;"
      class="w-100 bg-light pd-big radius-big"
    >
      <!-- Title -->
      <div 
        class="w-full h-full"
      >
        <!-- <Textarea 
          v-if="post" 
          :prop="post" 
          :setFocus="true"
          content="name" 
          placeholder="Enter post title" 
          class="h2"
        /> -->
      </div>

      <Constructor 
        v-if="post" 
        :content="post.content"
        @update="update => post.content = update"
      />
    </section>

   
  <transition  name="scaleIn" >
    <section v-if="post" class="pd-thin pos-sticky pos-l-0 pos-b-0 w-100 ">
      <div class="pd-thin radius-big  bg-main w-100 flex-nowrap flex">
        <a v-if="route.params.url" @click="onDelete()" class="mn-r-auto bg-red t-white t-black button">Delete</a>
        <a @click="onDrafts()" class="mn-l-auto bg-white t-black button">To Drafts</a>
        <a @click="openPulicationPopup()" class="mn-l-thin bg-black t-white button">Publish</a>
      </div>
    </section>
    </transition>

    <Popup  
      @close-popup="closePublicationPopup" 
      :isPopupOpen="isPublicationPopup"
      class="w-m-33r t-left pd-big bg-white radius-big"
    >
      <h3 class="mn-b-small">Final Touches</h3>
      
      <BlockTags
        @tags-changed="newTags => post.tags = newTags"
        :tags="post.tags"
        class="mn-b-small"
      />

      <h5 class="mn-b-thin">Add source:</h5>
      <Field 
        v-model:field="post.source"    
        placeholder="Add full link to the source" 
        class="mn-b-medium bg-light radius-medium pd-small" 
      />

      <h5 class="mn-b-thin">Add to public:</h5>
      <SelectMulti
        v-model="selectedOrganization"
        :options="publics"
        :multiple="false"
        :taggable="false"
        placeholder="Type to search or add tag"
        label="name"
        track-by="_id"
        class="mn-b-medium bg-light radius-medium pd-small"
      />
      <h5 v-if="selectedOrganization" class="mn-b-thin">Options:</h5>
      <div v-if="selectedOrganization" class="mn-b-medium br-grey-transp-25 br-2px br-solid pd-small radius-big">
        <Checkbox 
          :label="'Hide Author'"
          :radio="post.creator.hidden"
          @update:radio="event => post.creator.hidden = event"
          name="prices"
          class="w-100"
        />
        </div>
        
        <Button :submit="onSubmit" :callback="redirectTo" class="w-100 bg-black t-white">Publish</Button>
      </Popup>
      
  </article>
</template>

<script setup>
import Textarea     from '@pf/src/modules/constructor/components/elements/Textarea.vue';
import Constructor  from '@pf/src/modules/constructor/components/sections/Constructor.vue';

import Popup    from '@pf/src/components/Popup/Popup.vue'
import FieldTags    from '@pf/src/components/FieldTags/FieldTags.vue'
import BlockTags    from '@pf/src/components/FieldTags/BlockTags.vue'
import Checkbox    from '@pf/src/components/Checkbox/Checkbox.vue'
import Field from '@pf/src/components/Field/Field.vue'
import SelectMulti    from '@pf/src/components/SelectMulti/SelectMulti.vue'
import Button from '@pf/src/components/Button/Button.vue';   

import { ref, onMounted, watchEffect, nextTick, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { VueDraggableNext } from 'vue-draggable-next';
// Store & Router
import * as blog from '@pf/src/modules/community/store/blogposts.js';
import * as auth  from '@pf/src/modules/auth/views/store/auth'
import * as organizations  from '@pf/src/modules/organizations/store/organizations'

const route = useRoute();
const router = useRouter();

let post = ref(null);
let publics = ref(null);

const selectedTags = ref([]);
const selectedOrganization = ref(null);

onMounted(async () =>{
  const dataPublics = await organizations.actions.read({
    user: auth.state.user._id,
    postable: auth.state.user._id,
    limit: 100
  })

  publics.value = dataPublics.map(publicItem => ({
    name: publicItem.profile.name,
    _id: publicItem._id,
    photo: publicItem.profile.photo
  }));

  
  if (route.params.url) {
    const data = await blog.read({ user: auth.state.user._id, url: route.params.url });
    post.value = data.pop();
  } else {
    blog.clean();
    post.value = blog.state.current;
  }

  if (!post.value.owner) post.value.owner = {
    target: auth.state.user._id,
    type: 'user'
  }

  if (!post.value.creator) post.value.creator = {
    target: auth.state.user._id,
    type: 'user',
    hidden: false
  }


  if (post.value.owner.type === 'organization') selectedOrganization.value = {
    _id: post.value.owner.target._id,
    name: post.value.owner.target.profile.name,
    photo: post.value.owner.target.profile.photo
  }
})
// /////////////////////////////////////////
// Publication Form
// /////////////////////////////////////////
const tag = ref('');
const autocompleteItems = ref([
  { text: 'story' },
  { text: 'news' },
  { text: 'guide' },
  { text: 'discussion' },
  { text: 'photos' },
]);

const filteredItems = computed(() => {
  return autocompleteItems.value.filter(i => {
    return i.text.toLowerCase().includes(tag.value.toLowerCase());
  });
});

const filteredSuggestedItems = computed(() => {
    return autocompleteItems.value.filter(item => {
      return !selectedTags.value.some(tag => tag.text === item.text);
    });
  })

function addTag (tag) {
  selectedTags.value.push(tag)
}
// /////////////////////////////////////////
// Publication Popup
// /////////////////////////////////////////
const isPublicationPopup = ref(false)

function openPulicationPopup() {
  isPublicationPopup.value = true;
}
function closePublicationPopup() {
  isPublicationPopup.value = false;
}

function onDrafts() {

  if (selectedTags.value.length > 0) selectedTags.value.map(tag => (tag.text))

  post.value.status = "draft"
  post.value.name = post.value.content[0].content

  if (route.params.url) {
    blog.update(post.value)
      .then(response => {
        router.push({ name: 'BlogPost', params: { url: response.url } });
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    blog.create(post.value)
      .then(response => {
        router.push({ name: 'BlogPost', params: { url: response.url } });
      })
      .catch(error => {
        console.log(error);
      });
  }
}

function onSubmit() {

  if (selectedOrganization.value) post.value.owner = {
    target: selectedOrganization.value._id,
    type: 'organization'
  }

  if (!selectedOrganization.value) post.value.creator.hidden = false
  if (!selectedOrganization.value) post.value.organization = post.value.creator

  post.value.status = "published"
  post.value.name = post.value.content[0].content

  if (route.params.url) {
    blog.update(post.value)
      .then(response => {
        console.log(post.value)
        router.push({ name: 'BlogPost', params: { url: response.url } });
      })
      .catch(error => {
        console.error(error);
      });
  } else {
    blog.create(post.value)
      .then(response => {
        router.push({ name: 'BlogPost', params: { url: response.url } });
      })
      .catch(error => {
        console.error(error);
      });
  }
}

function onDelete() {
  if (confirm('Are you sure you want to delete this post?')) {
    blog.remove(post.value._id)
      .then(response => {
        router.push({ name: 'User Blogposts', params: { _id: post.value.creator.target._id } });
      })
      .catch(error => {
        console.log(error);
      });
  }
}
</script>


<style lang="scss">

</style>
