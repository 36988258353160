<template>
  <div class="pd-extra o-hidden w-100 container container-joinus">
    <div class="content">
      <span class="h0 mn-b-big">
        {{ userCount.toLocaleString() }}
      </span>
      <p class="w-m-33r t-center mn-b-small p-big">
        Together, we're shaping the future of the globals cannabis community.
      </p>
      <router-link :to="{ name: 'Sign In' }" class="bg-black t-medium t-white button">
        Join Now
      </router-link>
    </div>
    <FloatingImages 
        :container="'.container-joinus'"
        :images="[
            '/avatars/01.png',
            '/avatars/02.png',
            '/avatars/03.png',
            '/avatars/04.png',
            '/avatars/05.png',
            '/avatars/06.png'
        ]" 
        :config="{
            size: '5rem',
            square: true,
            minDuration: 5,
            maxDuration: 15,
            spawnPoints: [10, 90],
            animation: {
              startOpacity: 0.1,
              midOpacity: 1,
              endOpacity: 0.0,
              translateY: '-33vh'
            },
            class: 'my-custom-class'
        }"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import FloatingImages from '@pf/src/modules/landing/components/elements/FloatingImages.vue';

const userCount = ref(1230);

const increaseUserCount = () => {
  setInterval(() => {
    userCount.value += Math.floor(Math.random() * 2) + 1;
  }, 1330);
};

onMounted(() => {
  increaseUserCount();
});
</script>

<style lang="scss">
.container-joinus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66%;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }
}
</style>
