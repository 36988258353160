<template>
	<div id="popularProducts" class="bg-light radius-big pd-t-big pd-b-big">

		<h2 class="mn-b-semi t-center " v-html="t('title')"/>
		
		<div class="embla" ref="emblaNode">
		 	<div class="embla__container">
		   
		    <div class="embla__slide pd-nano" v-for="(value, key, index) in productsState" :key="key">
		    	<transition name="fade" mode="out-in" appear>
						<div 
							v-if="!value.name" 
							class="h-100 w-100 radius-big flex-center bg-light"
						>
							<Loader class="pos-relative"/>
						</div>
						
						<CardProduct 
							v-else 
							@click="$router.push({
			          name: 'Organization_Product', 
			          params: { 
			            _id: value.organization, 
			            product: value._id 
			          } 
			        })" 
							:product="value"
							class="cursor-pointer transition-ease-in hover-scale-0 h-100 w-100" 
						/>
					</transition>
		    </div>

    	</div>
		</div>
	</div>
</template>


<script setup scoped>
	import { ref, computed, onMounted } from 'vue'

	import { useRouter } 	from 'vue-router'
	import { useI18n } 		from 'vue-i18n'
		
	import emblaCarouselVue from 'embla-carousel-vue'; // Assuming a Vue version exists
	import Autoplay from 'embla-carousel-autoplay'

	import Loader from '@pf/src/components/Loader/Loader.vue'
	import CardProduct from '@pf/src/modules/products/components/blocks/CardProduct.vue'

	import * as products from '@pf/src/modules/products/store/products';
	
	const router = useRouter()

 	const text = {
    messages: {
      en: {
      	title: '<b>Best Sellers 🔥</b>',
      	subtitle: 'We attach great importance to high quality and customer satisfaction'
      },
      ru: {
      	title: 'Самое Горячее 🔥',
      	subtitle: 'Мы придаем большое значение высокому качеству товаров и удовлетворенности клиентов'
      }
    }
  }

	const { t } = useI18n(text)

	const productsState = ref(10)
	const selectedIndex = ref(0);
	const scrollSnaps = ref([]);

	const autoplayOptions = {
	  delay: 2000,
	  jump: false,
	  stopOnInteraction: false,
	  stopOnMouseEnter: false,
	  stopOnFocusIn: true,
	  stopOnLastSnap: false,
	  rootNode: (emblaRoot) => emblaRoot.parentElement
	}

	const [emblaNode, emblaApi] = emblaCarouselVue({ loop: true }, [Autoplay(autoplayOptions)])

	const scrollTo = (index) => emblaApi.value && emblaApi.value.scrollTo(index);
	const onInit = (embla) =>  scrollSnaps.value = emblaApi.value.scrollSnapList();
	const onSelect = (embla) => selectedIndex.value = emblaApi.value.selectedScrollSnap();

	onMounted(async() => {
		productsState.value = await products.actions.read({
			limit: 10, 
			organization: '657e7134efb8110c51b8b713'
		})

	  onInit(emblaApi);
	  onSelect(emblaApi);

	  emblaApi.value.on('reInit', onInit);
	  emblaApi.value.on('reInit', onSelect);
	  emblaApi.value.on('select', onSelect);
	});
</script>

<style lang="scss">
	.embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
  }
  .embla__slide {
    flex: 0 0 25%;
    min-width: 0;
  }

  @media screen and (max-width: 1025px) {
    .embla__slide {
	    flex: 0 0 50%;
	    min-width: 0;
	  }
  }
</style>