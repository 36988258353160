<template>
  <section class="pd-thin">
    <TransitionGroup tag="ul" name="fade" class="o-hidden bg-light radius-big">
      <SkeletonEvent
        v-if="isLoading"
        v-for="i in 3" :key="i"
      />

      <HeroEvent
        v-if="!isLoading && event && event.special"
        :content="{
          title: event.name,
          description: event.description,
          ticketLinkStripe: event.specialData.ticketLinkStripe,
          ticketText: event.specialData.ticketText,
          cover: event.cover,
          subtitle: event.specialData.subtitle,
          logos: event.specialData.logos,
          video: event.specialData.video,
          ticketTextButton: event.specialData.ticketTextButton,
        }"
        :options="{
          date: event.date.start,
          price: event.specialData.ticketPrice,
        }"
        class="mn-b-thin"
      />
      <SectionMainGuest
         v-if="!isLoading && event && event.special && event.specialData.guestTitle"
        :content="{
          title: event.specialData.guestTitle,
          description: event.specialData.guestDescription,
          socials: {
            twitter:  event.specialData.guestTwitter,
            telegram: event.specialData.guestTelegram,
            instagram: event.specialData.guestInstagram,
            facebook: event.specialData.guestFacebook
          },
          video: event.specialData.guestVideo,
          spotify: event.specialData.guestSpotify,
          soundcloud: event.specialData.guestSoundcloud,
          youtube: event.specialData.guestYoutube
        }"
        class="mn-b-thin"
      />
      <section 
        v-if="!isLoading && event && event.special"
        :style="`
          background-size: 100%; background-repeat: no-repeat;
          background-image: url(${event.specialData.lineupBackground}); 
        `" 
        class="mn-b-thin t-white gap-big w-100 h-max o-hidden radius-big pd-big pos-relative bg-black"
      >
        <h2 class="mn-b-big w-100 t-center">Event Details</h2>

        <div 
          class="gap-big mn-b-thin"
          :class="{
            'cols-2': event.specialData.guests.length > 0,
            'cols-1': event.specialData.guests.length > 1
          }"
        >
          
          <SectionSpecialGuests 
            v-if="event.specialData.guests.length > 0"
            :content="{
              title: 'Residents',
              guests: event.specialData.guests
            }"
          />

          <SectionLineUp
            v-if="event.specialData.lineup.lenth > 0"
            :content="{
              title: 'Lineup',
              lineup: event.specialData.lineup
            }"
          />

        </div>

        <SectionPreviousEvents
          :content="{
            ticket: event.specialData.ticketImage,
            ticketTextButton: event.specialData.ticketTextButton,
            paymentText: event.specialData.paymentText,
            paymentContact: event.specialData.paymentContact,
            previousTitle: event.specialData.previousTitle,
            previousDescription: event.specialData.previousDescription,
            previousLink: event.specialData.previousLink,
            previousLinkText: event.specialData.previousLinkText,
          }"
          :options="{
            link:  event.specialData.ticketLinkStripe,
            video: event.specialData.previousVideo
          }"
        />
    </section>

    <CardEvent 
        v-if="!isLoading && event && !event.special"
        @click="$router.push({name: 'Event', params: {url: event.url}})" 
        :event="event" 
        :owner="event.owner" 
        :user="auth.state.user._id"
        :type="'normal'"
        class="mn-b-medium bg-light radius-big"
      >
        <ButtonJoin 
          v-if="auth.state.user._id"
          :type="'event'" 
          :hasTicket="event.hasTicket" 
          :targetId="event._id" 
          :userId="auth.state.user._id"
          class="mn-b-small w-100 pd-medium mn-auto"
          @updateTicket="handleTicketUpdate"
        />

        <!-- <img loading="lazy" v-if="qrcode" :src="qrcode" class="w-8r h-8r" /> -->

        

        <section>
          <component
            v-if="event && event.content"
            v-for="(block, index) in event.content"
            :is="ComponentMap[block.type]"
            :key="index"
            :index="index"
            :component="block"
            :prop="block"
            :class="block.class"
            content="content"
            v-bind="getBlockProps(block)"
            class="mn-b-regular"
          />
        </section>

    </CardEvent>

  

    <Comments 
    	v-if="!isLoading && event && !event.special" 
    	:type="'event'" 
    	:target="event._id" 
    	:owner="auth.state.user._id"
    />
      
  </TransitionGroup>  

  </section>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  
  import Loader from '@pf/src/components/Loader/Loader.vue';
  import EmptyState from '@pf/src/components/EmptyState/EmptyState.vue';
    
  import HeroEvent from '@pf/src/modules/events/components/sections/HeroEvent.vue';
  import SectionMainGuest from '@pf/src/modules/events/components/sections/SectionMainGuest.vue';
  import SectionSpecialGuests from '@pf/src/modules/events/components/sections/SectionSpecialGuests.vue'
  import SectionLineUp from '@pf/src/modules/events/components/sections/SectionLineup.vue';
  import SectionPreviousEvents from '@pf/src/modules/events/components/sections/SectionPreviousEvents.vue';


  import CardEvent from '@pf/src/modules/events/components/blocks/CardEvent.vue';
  import SkeletonEvent from '@pf/src/modules/icons/skeletons/SkeletonEvent.vue'
  import ButtonJoin from '@pf/src/modules/events/components/elements/ButtonJoin.vue';
  import Comments from '@pf/src/modules/community/components/sections/Comments.vue';

  import Image  from '@pf/src/modules/constructor/components/elements/Image.vue';

  import * as events from '@pf/src/modules/events/store/events.js';
  import * as tickets from '@pf/src/modules/events/store/tickets.js';
  import * as auth from '@pf/src/modules/auth/views/store/auth';
  
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const qrcode = ref(null);
  
  const handleTicketUpdate = ({ ticket, hasTicket, targetId }) => {
    tickets.mutations.handleTicketUpdate(event.value, ticket, hasTicket, targetId);
    console.log('HANDLE TICKET UPDATE', ticket, event.value);

    qrcode.value = ticket.qrcode;
  };


  const ComponentMap = {
    'Textarea': 'p',
    'H2': 'h2',
    'Bullets': 'p',
    'Caption': 'p',
    'ImageUpload': Image
  };

  function getBlockProps(block) {
    if (block.type === 'Textarea') {
      return { innerHTML: block.content, class: "p-medium"  };
    } else if (block.type === 'H2') {
      return { innerHTML: block.content, class: "h3" };
    } else if (block.type === 'Caption') {
      return { innerHTML: block.content };
    } else if (block.type === 'ImageUpload') {
      return { src: block.content };
    }
    return {};
  }


  const event = ref(null);
  const isLoading = ref(true);

  onMounted(async () => {
    isLoading.value = true;

    const data = await events.read({ user: auth.state.user._id, url: route.params.url });

    event.value = data[0];
    isLoading.value = false;
  });
</script>

<style lang="scss">

</style>
