<template>
  <div>
  <header 
    v-if="route.name !== 'Organization'"
    class="mn-b-medium flex-v-center flex-nowrap flex"
  >
    <h2 class="mn-r-medium">Products</h2>
    <button 
      @click="$router.push({
        name: 'ProductAdd'
      })" 
      class="radius-100 i-big hover-scale-1 cursor-pointer t-white bg-second">
        +
    </button>
  </header>

  <Tab 
    v-if="route.name !== 'Organization'"
    v-model:selected="tabProducts"
    :tabs="[
      { name: 'All',          value: 'all' },
      { name: 'Published',    value: 'published' },
      { name: 'Unpublished',  value: 'unpublished' }, 
      { name: 'Archivied',    value: 'archivied' } 
    ]"
    class="mn-b-small o-hidden h5 radius-big bg-light"
  />

  <div class="cols-2-1_3 bg-light pd-thin radius-big gap-thin">

    <BlockFilter
      v-model:filter="marketplace.state.filter"
      :options="marketplace.state.filter.options"
      class="h-100 w-100 desktop-only"
    />


    <div class="rows-1">
      <!-- <BlockSearch 
        @search="updateSearch"
        placeholder="Enter product name"
        class="bg-white mn-b-thin"
      /> -->
      <Feed
        v-model:filter="marketplace.state.filter"
        :search="true"
        :states="{
          empty: {
            title: 'No Products Found',
            description: 'Currently, there are no products available.'
          }
        }"
        :store="{
          read: (options) => products.actions.read(options),
          state: products.state
        }"
        :options="{
          limit: 15,
          organization: route.params._id,
          categories: route.query.categories,
          prices: route.query.prices,
          delivery: route.query.delivery,
          // search: route.query.search,
          ...(tabProducts !== 'all' && { status: tabProducts })
          // user: user
        }"
        v-slot="{ 
          items 
        }"
        class="cols-4 gap-thin"
      >
        <CardProduct
          v-for="product in items"
          :key="product._id"
          :product="product"
          :user="auth.state.access"
          class="h-max"
          @click.capture="$router.push({
            name: 'Organization_Product', 
            params: { 
              _id: route.params._id, 
              product: product._id 
            } 
          })" 
        />
      </Feed>
    </div>
  </div>
</div>
</template>


<script setup="props">
  // Import libs
  import { computed, watch, onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  // Import components
  import Tab from '@pf/src/components/Tab/Tab.vue'
  import Feed from '@pf/src/components/Feed/Feed.vue'

  import FilterProducts from '@pf/src/modules/products/components/sections/FilterProducts.vue'
  import BlockSearch from '@pf/src/modules/globals/views/components/blocks/BlockSearch.vue'
  import BlockFilter  from '@pf/src/modules/globals/views/components/blocks/BlockFilter.vue'

  import CardProduct from '@pf/src/modules/products/components/blocks/CardProduct.vue'

  // Accessing router and store
  import * as auth from '@pf/src/modules/auth/views/store/auth';
  import * as products from '@pf/src/modules/products/store/products';
  import * as marketplace from '@pf/src/modules/products/store/marketplace';
  import * as categories from '@pf/src/modules/products/store/categories';

  const route = useRoute()
  const router = useRouter()

  // Tab logic
  const tabProducts = ref(route.query.tabProducts ? route.query.tabProducts : 'all')

  if (route.name !== 'Organization') route.query.tabProducts = tabProducts.value
  
  if (route.query) {
    const query = route.query;

    marketplace.state.filter.selected.categories = query.categories ? query.categories.split(',') : [];
    marketplace.state.filter.selected.prices = query.prices ? query.prices.split(',') : [];
    marketplace.state.filter.selected.delivery = query.delivery ? query.delivery.split(',') : [];
  }


  watch(tabProducts, (newValue) => {
    if (route.name !== 'Organization') router.replace({ query: { ...route.query, tabProducts: newValue } });
  });

  onMounted(async () => {
    let categoriesFilter = await categories.actions.fetchCategories();

    categoriesFilter = categoriesFilter.map(category => ({
      value: category.url,
      label: category.name
    }));

    const index = marketplace.state.filter.options.findIndex(option => option.value === 'categories');

    const categoryOption = {
      title: 'Categories',
      value: 'categories',
      options: categoriesFilter
    };

    if (index !== -1) {
        marketplace.state.filter.options[index] = categoryOption;
    } else {
        marketplace.state.filter.options.unshift(categoryOption);
    }
  })

  watch(() => marketplace.state.sort, (newSortValue, oldSortValue) => {
    let query = { ...route.query}
    
    query.sortParam = newSortValue.param
    query.sortOrder = newSortValue.order
    
    // replace the current route
    router.replace({ query });
  }, { deep: true });


  watch(() => marketplace.state.filter.selected, (newFilterValue, oldFilterValue) => {
    // Переводим фильтр в формат query
    const query = { ...route.query };

    // Удаляем старые значения фильтра из query
    Object.keys(oldFilterValue).forEach(key => {
      if (query[key]) {
        delete query[key];
      }
    });

    // Добавляем новые значения фильтра в query
    const newQueryValues = Object.fromEntries(
      Object.entries(newFilterValue)
        .filter(([key, value]) => Array.isArray(value) && value.length > 0)
        .map(([key, value]) => [key, value.join(',')])
    );

    delete query.options;
    Object.assign(query, newQueryValues);

    // Обновляем маршрут с новым query
    router.replace({ query });
  }, { deep: true })



  
  function updateSearch(search) {
    let query = { ...route.query}
    
    query.search = search
    
    router.replace({ query });
  }
</script>

<style lang="scss">


</style>
