<template>
	<div class="z-index-1 pos-relative radius-big bg-black t-white pd-medium flex-v-center flex-nowrap flex">
    <div class="mn-r-auto pd-micro gap-micro br-solid br-2px br-white  radius-extra flex-nowrap flex"
    >
  		<Dropdown 
        id="fitlerCategory"
  			:label="t('categoriesTitle')" 
  			class="cursor-pointer pd-thin t-semi radius-extra uppercase p-medium"
  			:class="[
          marketplace.state.filter.categories.length > 0 ? 'bg-white t-black' : ''
        ]"
  		>
  			<div class="w-100 h-100 radius-semi o-hidden bg-black">
        	<Checkbox 
    				v-for="(category, index) in categories"
            :label="category.name"
            name="categories"
            :value="category.url"
            class="cursor-pointer  w-100  bg-black t-white radius-small pd-small"
            :radio="marketplace.state.filter.categories"
            @update:radio="event => marketplace.state.filter.categories = event"
          />
       	</div>
       </Dropdown>

      <Dropdown 
        id="fitlerPrice"
       	:label="t('filters.price.title')" 
       	class="cursor-pointer pd-thin t-semi radius-extra uppercase p-medium"
       	:class="[
          marketplace.state.filter.prices.length > 0 ? 'bg-white t-black' : ''
        ]"
       >
        <div class="w-100 h-100 radius-semi o-hidden bg-black">
        	<Checkbox 
    				v-for="(price, index) in prices"
            :label="price.label"
            name="prices"
            :value="price.value"
            class="cursor-pointer  w-100 mn-t-small bg-black t-white radius-small pd-small"
            :radio="marketplace.state.filter.prices"
            @update:radio="event => marketplace.state.filter.prices = event"
          />
        </div>
       </Dropdown>

      <Dropdown 
     	  label="Delivery" 
        id="fitlerDelivery"
       	class="cursor-pointer pd-thin t-semi radius-extra uppercase p-medium"
       	:class="[
          marketplace.state.filter.delivery.length > 0 ? 'bg-white t-black' : ''
        ]"
      >
        <div class="w-100 h-100 radius-semi o-hidden bg-black">
        	<Checkbox 
    				v-for="(delivery, index) in delivery"
            :label="delivery.label"
            name="prices"
            :value="delivery.value"
            class="cursor-pointer  w-100 mn-t-small bg-black t-white radius-small pd-small"
            :radio="marketplace.state.filter.delivery"
            @update:radio="event => marketplace.state.filter.delivery = event"
          />
        </div>
      </Dropdown>
    </div>
  </div>

</template>

<script setup>
	import { computed, onMounted, ref } from 'vue'

 	import Checkbox from "@pf/src/components/Checkbox/Checkbox.vue";
  import Radio from "@pf/src/components/Radio/Radio.vue";
 	import Select from "@pf/src/components/Select/Select.vue";
 	import Dropdown from "@pf/src/components/Dropdown/Dropdown.vue";

	import { useI18n } from 'vue-i18n'

	import * as marketplace from '@pf/src/modules/products/store/marketplace';
  import * as categories from '@pf/src/modules/products/store/categories';

	const delivery = [
    { label: 'Pickup',					value: 'pickup' },
    { label: 'Courier',					value: 'courier' },
    { label: 'Post',						value: 'post' }
	]

	const prices = [
    { label: 'Under 300฿',				value: '<300' },
    { label: '300฿ to 600฿',			value: '300-600' },
    { label: '600฿ to 1200฿',			value: '600-1200' },
    { label: '$1200 and above',		value: '>1200' }
	]

	const text = {
    locale: 'en',
    messages: {
      en: {
      	categoriesTitle: 'Category',
      	categories: [],
      	sort: {
      		price: 'By price',
      		newest: 'By newest',
      		popularity: 'By popularity'
      	},
      	filters: {
      		title: 'Filters',
      		price: {
      			title: 'Price',
      			from: 'From',
      			to: 'To'
      		},
      		filters: [],
      		reset: 'Reset Filters'
      	}
      },
      ru: {
      	categoriesTitle: 'Категория',
      	sort: {
      		price: 'По цене',
      		newest: 'По новизне',
      		popularity: 'По популярности'
      	},
      	filters: {
      		title: 'Фильтры',
      		price: {
      			title: 'Цена',
      			from: 'От',
      			to: 'До'
      		},
      		filters: [],
      		reset: 'Сбросить фильтры',
      	}
      }
    }
  }

	const { t } = useI18n(text)

	onMounted(async () => {
    let options = {
      status: 'published'
    }

		await categories.actions.fetchCategories(options)
	})

	
</script>