// universal-entry.js
import { createSSRApp, createApp } from 'vue';
import { createHead } from '@unhead/vue'

import { layoutApp, appRenderer } from '@pf/src/modules/globals/globals.client';

import store from './store';
import i18n from "./localization";
import { _createRouter } from './router';

import globals from '@pf/src/modules/globals/globals.client';
import auth from '@pf/src/modules/auth/auth.client';
import chats from '@pf/src/modules/chats/chats.client';
import backoffice from '@pf/src/modules/backoffice/backoffice.client';
import organizations from '@pf/src/modules/organizations/organizations.client';
import community from '@pf/src/modules/community/community.client';
import gallery from '@pf/src/modules/gallery/gallery.client';
import events from '@pf/src/modules/events/events.client';
import orders from '@pf/src/modules/orders/orders.client';
import pages from '@pf/src/modules/pages/pages.client';
import products from '@pf/src/modules/products/products.client';
import spots from '@pf/src/modules/spots/spots.client';
import reports from '@pf/src/modules/reports/reports.client';

import '@/scss/theme.scss';

export function _createApp() {
  let options = {
    messages: {
      en: {
        meta: {
          title: "Weeder Delivery – Phuket Cannabis Marketplace with Sameday Delivery",
          description: "Weeder Delivery stocks a wide range of quality CBD & high THC weed strains available for purchase whether you are a local, expat or tourist. We also receive new cannabis strains for our clients to enjoy daily!",
          keywords: "Cannabis, Weed, CBD, THC, Delivery, Phuket, Marijuana, Strains, Local, Expat, Tourist",
          og: {
            title: "Weeder Delivery – Phuket Cannabis Marketplace with Sameday Delivery",
            description: "Weeder Delivery stocks a wide range of quality CBD & high THC weed strains available for purchase whether you are a local, expat or tourist. We also receive new cannabis strains for our clients to enjoy daily!",
            image: "/favicon/cover.jpg"
          },
          twitter: {
            title: "Weeder Delivery – Phuket Cannabis Marketplace with Sameday Delivery",
            description: "Weeder Delivery stocks a wide range of quality CBD & high THC weed strains available for purchase whether you are a local, expat or tourist.",
            image: "/favicon/cover.jpg",
            card: "summary_large_image"
          },
          htmlAttrs: {
            lang: "en"
          }
        },
      },
      ru: {
        meta: {
          title: "Weeder Delivery – доставка каннабиса на Пхукете за 1 час",
          description: "Weeder Delivery предлагает широкий ассортимент качественных сортов с CBD и высоким содержанием THC, доступных для покупки независимо от того, являетесь ли вы местным, эмигрантом или туристом. Мы также ежедневно получаем новые сорта каннабиса для наших клиентов!",
          keywords: "Каннабис, Марихуана, CBD, THC, Доставка, Пхукет, Сорта, Местные, Эмигранты, Туристы",
          og: {
            title: "Weeder Delivery – доставка каннабиса на Пхукете за 1 час",
            description: "Weeder Delivery предлагает широкий ассортимент качественных сортов с CBD и высоким содержанием THC, доступных для покупки независимо от того, являетесь ли вы местным, эмигрантом или туристом. Мы также ежедневно получаем новые сорта каннабиса для наших клиентов!",
            image: "/favicon/cover.jpg"
          },
          twitter: {
            title: "Weeder Delivery – доставка каннабиса на Пхукете за 1 час",
            description: "Weeder Delivery предлагает широкий ассортимент качественных сортов с CBD и высоким содержанием THC, доступных для покупки.",
            image: "/favicon/cover.jpg",
            card: "summary_large_image"
          },
          htmlAttrs: {
            lang: "ru"
          }
        }
      }
    }
  }

  const app = process.env.MOBILE_APP ? createApp(layoutApp, options) : createSSRApp(layoutApp, options);

  const router = _createRouter();
  const meta = createHead()

  globals.initialize(app, store, router)
  auth.initialize(app, store, router)
  backoffice.initialize(app, store, router)
  organizations.initialize(app, store, router)
  community.initialize(app, store, router)
  events.initialize(app, store, router)
  gallery.initialize(app, store, router)
  orders.initialize(app, store, router)
  products.initialize(app, store, router)
  spots.initialize(app, store, router)
  pages.initialize(app, store, router)
  reports.initialize(app, store, router)

  app
    .use(router)
    .use(i18n)
    .use(meta)

  if (process.env.MOBILE_APP) {
    app.mount("#app");
  } else {
    return {
      app,
      router,
      store,
      i18n,
      meta
    }
  }
};

// FOR SPA

if (typeof window !== 'undefined' && process.env.MOBILE_APP) {
  _createApp();
}

// FOR SSR / CLIENT ENTRY
if (typeof window !== 'undefined' && !process.env.MOBILE_APP) {
  appRenderer.renderAndMountApp({ createApp: _createApp });
}

// FOR SSR / SERVER ENTRY
export async function render({ url, cookies, languages }) {
  return appRenderer.render({
    url,
    cookies,
    createApp: _createApp
  });
}