// Router
import { createProductRoutes } from './router/products.router';
import routerMarketplace from './router/marketplace.router.js';

//Store
import * as storeCategories from './store/categories.js';
import * as storeLeftovers from './store/leftovers.js';
import * as storeProducts from './store/products.js';
import * as storeMarketplace from './store/marketplace.js';

// Layouts 
import Marketplace from './components/layouts/Marketplace.vue';

// Blocks
import ImagesThumbnails from './components/blocks/ImagesThumbnails.vue';
import LeftoverPositions from './components/blocks/LeftoverPositions.vue';
import ListPositions from './components/blocks/ListPositions.vue';
import CardCategory from './components/blocks/CardCategory.vue';
import CardProduct from './components/blocks/CardProduct.vue';
import CardLeftover from './components/blocks/CardLeftover.vue';
import Images360 from './components/blocks/Images360.vue';
import CardPosition from './components/blocks/CardPosition.vue';

// Sections
import SectionProduct from './components/sections/SectionProduct.vue';
import HeroRecommendation from './components/sections/HeroRecommendation.vue';
import FilterProducts from './components/sections/FilterProducts.vue';
import MenuSection from './components/sections/MenuSection.vue';
import EditModifications from './components/sections/EditModifications.vue';
import PopularProducts from './components/sections/PopularProducts.vue';
import EditProductInfo from './components/sections/EditProductInfo.vue';
import Filters from './components/sections/Filters.vue';

// Elements
import Price from './components/elements/Price.vue';
import THC from './components/elements/THC.vue';

// Pages
import Product from './components/pages/Product.vue';
import ProductEdit from './components/pages/ProductEdit.vue';
import EditLeftover from './components/pages/EditLeftover.vue';
import ProductRecommendation from './components/pages/ProductRecommmendation.vue';
import Products from './components/pages/Products.vue';
import Leftovers from './components/pages/Leftovers.vue';
import Catalog from './components/pages/Catalog.vue';

// Пример функции инициализации для модуля продуктов
function initializeProducts(app, store, router, options = {}) {
  const route = options.route || 'Home';

  const routesProducts = createProductRoutes();
  
  router.addRoute(route, routesProducts);

  router.addRoute(route, routerMarketplace);
  
  store.addStore('categories', storeCategories);
  store.addStore('leftovers', storeLeftovers);
  store.addStore('products', storeProducts);
  store.addStore('marketplace', storeMarketplace);
}

const ModuleProducts = {
  initialize: initializeProducts,
  views: {
    store: {
      storeCategories,
      storeLeftovers,
      storeProducts,
      storeMarketplace,
      routerMarketplace
    },
    router: {
      createProductRoutes
    },
    components: {
      // Elements
      Price,
      THC,
      // Blocks
      ImagesThumbnails,
      LeftoverPositions,
      ListPositions,
      CardCategory,
      CardProduct,
      CardLeftover,
      Images360,
      CardPosition,
      // Sections
      SectionProduct,
      HeroRecommendation,
      FilterProducts,
      MenuSection,
      EditModifications,
      PopularProducts,
      EditProductInfo,
      Filters,
      // Pages
      Product,
      ProductEdit,
      EditLeftover,
      ProductRecommendation,
      Products,
      Leftovers,
      Catalog,
      // Layouts
      Marketplace
    }
  }
}

export {
  // Blocks
  ImagesThumbnails,
  LeftoverPositions,
  ListPositions,
  CardCategory,
  CardProduct,
  CardLeftover,
  Images360,
  CardPosition,
  // Sections
  SectionProduct,
  HeroRecommendation,
  FilterProducts,
  MenuSection,
  EditModifications,
  PopularProducts,
  EditProductInfo,
  Filters,
  // Elements
  Price,
  THC,
  // Pages
  Product,
  ProductEdit,
  EditLeftover,
  ProductRecommendation,
  Products,
  Leftovers,
  Catalog,
  // Layouts
  Marketplace
};

export default ModuleProducts;