<template>

  	<div id="mainguest" class="o-hidden bg-black t-white pos-relative radius-big gap-zero pos-relative cols-2">

	    <div class="pd-big pos-relative flex-column flex w-100 h-100 gap-zero">
				 <Socials
		    	:telegram="content?.socials?.telegram"
	     		:facebook="content?.socials?.facebook"
	     		:instagram="content?.socials?.instagram"
	     		:twitter="content?.socials?.twitter"
	     		class="mn-b-semi"
		    />

	    	<h2 class="mn-b-semi">
					{{content?.title}}
		    </h2>

		    <p class="p-medium t-transp" v-html="content?.description"/>
		    <slot></slot>
		    <iframe class="radius-big o-hidden mn-t-medium" v-if="content?.soundcloud" width="100%" height="200" scrolling="no" frameborder="no" allow="autoplay"
					:src="`https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/${content.soundcloud}
					&color=%23ff5500&auto_play=false&hide_related=false
					&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`">
					</iframe>
		    <iframe 
		    	v-if="content?.spotify" 
		    	:src="`https://open.spotify.com/embed/track/${content?.spotify}?utm_source=generator`" 
		    	width="100%" 
		    	height="152" 
		    	frameBorder="0" 
		    	allowfullscreen="" 
		    	allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
		    	loading="lazy"
		    	class="mn-t-thin radius-big o-hidden"
		    ></iframe>
		    <iframe v-if="content?.youtube"  width="100%" class="radius-big mn-t-semi" height="315" :src="`https://www.youtube.com/embed/${content?.youtube}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	    </div>

	    <div style="min-height: 20rem;" class="o-hidden pos-relative">
				<video 
					:src="content?.video" 
					itemprop="video" 
					type="video/mp4"
					ref="videoElement"  
					class="pos-absolute pos-t-0 pos-l-0 w-100 h-100 object-fit-cover" 
					preload autoplay muted loop playsinlineclass playsinline></video>
	    </div>

  	</div>
</template>


<script setup>
	import { useI18n } from 'vue-i18n'

	import { ref, onMounted } from 'vue';

	import Socials from '@pf/src/modules/organizations/components/blocks/Socials.vue'

	const props = defineProps({
		content: {
			type: Object
		}
	})

	const videoElement = ref(null)
	let isPlaying = false;

	function	checkAndPlayVideo() {
    // Initializing values

		// On video playing toggle values
		videoElement.value.onplaying = function() {
		  isPlaying = true;
		};

		// On video pause toggle values
		videoElement.value.onpause = function() {
		  isPlaying = false;
		};	
  }

  // Play video function
	async function playVid() {      
    if (videoElement.value.paused && !isPlaying) {
      return videoElement.value.play();
    }
	} 
  
	onMounted(() => {
		checkAndPlayVideo()
		playVid()
	})

</script>

<style lang="scss">
	 @media screen and (max-width: 1025px) {
	 	#mainguest {
	 		> div:first-of-type {
	 			order: 2;
	 		}
	 	}
	 }
</style>
