<template>
  <div class="o-scroll product-images">
    <div class="no-responsive flex flex-nowrap gap-thin">
      <img loading="lazy" 
        v-for="(image, index) in images"
        :key="index"
        :src="(FILE_SERVER_URL || '') + image" 
        @click="openPopup(image)"
        class="flex-child flex-child-grow-1 aspect-1x1 radius-semi bg-white o-hidden thumbnail"
      />
    </div>
    <Popup @close-popup="closePopup" :isPopupOpen="isPopupVisible" class="radius-medium o-hidden">
      <PhotoViewer
        :photoUrl="(FILE_SERVER_URL || '') + selectedImage"
      />
    </Popup>
  </div>
</template>

<script setup>
import { ref } from "vue";

import Popup from '@pf/src/components/Popup/Popup.vue'
import PhotoViewer from '@pf/src/components/PhotoViewer/PhotoViewer.vue'

const props = defineProps({
  images: {
    type: Array,
    required: true,
  }
});

const isPopupVisible = ref(false);
const selectedImage = ref(null);

const openPopup = (image) => {
  selectedImage.value = image;
  isPopupVisible.value = true;
};

const closePopup = () => {
  isPopupVisible.value = false;
};
</script>

<style scoped>
.magnifier {
  max-width: 32rem;
}
.product-images {
  display: flex;
  flex-direction: column;
}

.thumbnail {
  width: 100%;
  max-width: 8rem;
  height: 8rem;
  object-fit: cover;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
}

.popup-image {
  max-width: 90%;
  max-height: 90%;
}
</style>