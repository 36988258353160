<template>
  <section class="pos-relative">
    <TransitionGroup tag="ul" name="fade" class="o-hidden bg-light radius-big">
      <SkeletonOrganization
        v-if="isLoading"
        v-for="i in limit" :key="i"
      />
    </TransitionGroup>

    <transition name="fade">
      <EmptyState 
        v-if="!isLoading && organizationsList.length < 1"
        title="No Organizations Found"
        description="Currently, there are no organizations available. Please check back later."
        class="pd-medium mn-b-thin bg-light radius-big"
      />
    </transition>

    <TransitionGroup tag="ul" name="fade" class="cols-1">
      <CardOrganization 
        v-if="!isLoading && organizationsList.length > 0"
        @click="$router.push({name: 'Organization', params: {_id: organization._id}})" 
        v-for="(organization,index) in organizationsList" 
        :key="organization._id" 
        :organization="organization" 
        :user="user" 
        :type="'normal'"
        class="w-100 radius-big mn-b-medium"
      />
      <button v-if="hasMoreOrganizations && organizationsList.length > 0" @click="loadMoreOrganizations" class="w-100 bg-main button">Load more</button> 
    </TransitionGroup>  
  </section>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  
  import Loader from '@pf/src/components/Loader/Loader.vue';
  import EmptyState from '@pf/src/components/EmptyState/EmptyState.vue';
  
  import CardOrganization from '@pf/src/modules/organizations/components/blocks/CardOrganization.vue';
  import SkeletonOrganization from '@pf/src/modules/icons/skeletons/SkeletonOrganization.vue'

  import * as organizations from '@pf/src/modules/organizations/store/organizations.js';

  const props = defineProps({
    category: {
      type: String,
      default: null
    },
    selectedDate: {
      type: String,
      default: null
    },
    organization: {
      type: String,
      default: null
    },
    owner: {
      type: String,
      default: null
    },
    user: {
      type: [String, Number],
      default: null
    },
    member: {
      type: [String, Number],
      default: null
    },
    subscriber: {
      type: [String, Number],
      default: null
    },
    sortOrder: {
      type: String,
      default: 'desc'
    },
    participant: {
      type: String,
      default: null
    },
    period: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  });

  const organizationsList = ref([]);
  const hasMoreOrganizations = ref(false);
  const isLoading = ref(true);

  const skip = ref(0);
  const limit = ref(20);

  const loadMoreOrganizations = async () => {
    if (hasMoreOrganizations.value) {
      skip.value += limit.value;
      
      const data = await organizations.actions.read({
        skip: skip.value,
        limit: limit.value,
        category: props.category,
        sortOrder: props.sortOrder,
        member: props.member,
        subscriber: props.subscriber,
        onlyuser: props.onlyuser,
        user: props.user,
        date: props.selectedDate,
        owner: props.owner,
        organization: props.organization,
        period: props.period,
        status: props.status
      });

      if (data.length === 0) {
        hasMoreOrganizations.value = false;
      } else {
        hasMoreOrganizations.value = true;
      }

      organizationsList.value = [...organizationsList.value, ...data];
    }
  };

  onMounted(async () => {
    isLoading.value = true;
    
    const data = await organizations.actions.read({
      skip: skip.value,
      limit: limit.value,
      category: props.category,
      member: props.member,
      subscriber: props.subscriber,
      onlyuser: props.onlyuser,
      sortOrder: props.sortOrder,
      user: props.user,
      owner: props.owner,
      date: props.selectedDate,
      organization: props.organization,
      period: props.period,
      status: props.status
    });

    if (data.length === 0) {
      hasMoreOrganizations.value = false;
    } else {
      hasMoreOrganizations.value = true;
    }

    organizationsList.value = data;
    isLoading.value = false;
  });
</script>

<style lang="scss">

</style>
