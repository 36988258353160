<template>
	<div>
		<p v-if="product.modifications.length == 0" class="mn-b-small w-100">У продукта еще не заданы модификации.</p> 
		
		<!-- Modification Info -->
		<div class="mn-b-small flex-column flex-wrap block" v-for="(modification, index) in product.modifications" :key="index"> 
			<div class="w-100 flex-nowrap flex"> 
				<Field
					v-model:field="modification.quantity"
					:disabled="true"
					placeholder="Количество"
					class="mn-b-medium input-text input"
					type="number"
				/>
				<Field
					v-model:field="modification.name"
					placeholder="Новая модификация"
					class="mn-b-medium input-text input"
				/>
				<button @click="deleteModification(index)" class="mn-l-small i-normal pd-regular button-delete button">
					<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="24" height="24" transform="translate(1 1)" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9996 10.8L22.8994 0.900244L25.0993 3.1002L15.1995 13L25.0993 22.8998L22.8994 25.0998L12.9996 15.2L3.09976 25.0998L0.899801 22.8998L10.7996 13L0.899801 3.1002L3.09976 0.900244L12.9996 10.8Z" fill="rgb(var(--white))"/>
					</svg>

				</button>
			</div>	
			<hr  class="mn-b-medium">
			<!-- Modification Characteristic -->
			<div class="mn-b-medium w-100 flex-nowrap flex variants-wrapper" v-for="(characteristic, index2) in modification.characteristics" :key="index2"> 
				<Field
					v-model:field="characteristic.name"
					placeholder="Название параметра"
					class="input-text input"
				/>
				<Field
					v-model:field="characteristic.value"
					placeholder="Значение параметра"
					class="mn-l-small input-text input"
				/>
				<button @click="deleteCharacteristic(index, index2)"  class="mn-l-small i-normal pd-regular button-delete button">
					<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="24" height="24" transform="translate(1 1)" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9996 10.8L22.8994 0.900244L25.0993 3.1002L15.1995 13L25.0993 22.8998L22.8994 25.0998L12.9996 15.2L3.09976 25.0998L0.899801 22.8998L10.7996 13L0.899801 3.1002L3.09976 0.900244L12.9996 10.8Z" fill="rgb(var(--white))"/>
					</svg>

				</button>
			</div>
			<hr  class="mn-b-medium">
			<button @click="addCharacteristic(index)" class="button">Добавить характеристику</button>
		</div>
		
		<div class="mn-b-big t-center flex-center flex block">
			<button @click="addModification" class="w-100">Добавить модификацю</button>
		</div>
		<button @click="onSubmit()" class="button">Сохранить модификации</button>
		
	</div>	
</template>

<script setup>
import { toRefs, ref, defineEmits } from 'vue';
import Field from "@pf/src/components/Field/Field.vue";

const emit = defineEmits(['add-modification', 'delete-modification', 'add-characteristic', 'delete-characteristic', 'submit'])

const props = defineProps({
  product: Object,
});

const { product } = toRefs(props);

function addModification() {
	emit('add-modification');
}

function deleteModification(index) {
	emit('delete-modification', index);
}

function addCharacteristic(index) {
	emit('add-characteristic', index);
}

function deleteCharacteristic(modIndex, charIndex) {
	emit('delete-characteristic', {modIndex, charIndex});
}

function onSubmit() {
	emit('submit');
}
</script>
