import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

export function createProductRoutes(prefix = '', options = {}) {
  const nameWithPrefix = (name) => prefix ? `${prefix}${name}` : name;

  const routes = {
    path: options.basePath || 'products',
    children: [
      {
        path: '',
        name: nameWithPrefix('Products'),
        meta: {
          title: {
            en: 'Products',
            ru: 'Товары'
          },
          authorize: options.productsAuthorize || []
        },
        component: () => import(/* webpackChunkName: 'Products' */ '@pf/src/modules/products/components/pages/Products.vue'),
      },
      {
        path: 'add',
        name: nameWithPrefix('ProductAdd'),
        meta: {
          title: {
            en: 'Add Product',
            ru: 'Добавить товар'
          },
          authorize: options.addProductAuthorize || []
        },
        component: () => import('@pf/src/modules/products/components/pages/ProductEdit.vue')
      },
      {
        path: ':product',
        name: nameWithPrefix('Product'),
        meta: {
          title: {
            en: 'Product',
            ru: 'Продукт'
          }
        },
        component: () => import('@pf/src/modules/products/components/pages/Product.vue')
      },
      {
        path: ':product/edit',
        name: nameWithPrefix('ProductEdit'),
        meta: {
          title: {
            en: 'Edit Product',
            ru: 'Редактировать продукт'
          },
          authorize: options.editProductAuthorize || []
        },
        beforeEnter: [validationAuth.requiresAuth],
        component: () => import('@pf/src/modules/products/components/pages/ProductEdit.vue')
      },
      {
        path: 'recommendation',
        name: nameWithPrefix('ProductRecommmendation'),
        meta: {
          title: {
            en: 'Product Recommmendation',
            ru: 'Рекомендации продукта'
          },
          header_theme: 'dark',
          footer_theme: 'dark',
        },
        component: () => import(/* webpackChunkName: 'Product Recommmendation' */ '@pf/src/modules/products/components/pages/ProductRecommmendation.vue'),
        props: route => ({ mood: route.query.mood })
      }
    ]
  };

  return routes;
}