<template>
	<div class="for-transition w-100 cols-1 pd-thin gap-thin">
    
    <Block class="flex-nowrap flex-v-center flex">
      
      <h1 class="mn-r-auto">
			  {{ route.params.product ? products.state.current.name : 'Create Product' }}
			</h1>

			<Button 
				v-if="route.params.product"
      	:submit="onDelete" 
      	:callback="redirectTo"
      	class="bg-red mn-r-thin"
      >
    		Delete 
    	</Button>

      <Button 
      	:submit="onSubmit" 
      	:callback="redirectTo"
      	class="bg-main"
      >
    		Save
    	</Button>
    </Block>

    <Block
			placeholder="The product doesn't have any images yet."
			class="h-min"
		>
			<EditImages 
				:images="products.state.current.images"
				:uploadPath="'photos'" 
				@update:images="(imagesNew) => { products.state.current.images = imagesNew }" 
			/>
		</Block>

    <div class="cols-2 gap-thin">

    	<Block
  			title="Profile"
  		>
				<EditProductInfo 		
					:product="products.state.current"
				/>
			</Block>

			<Block
		    title="Ingredients"
				placeholder="No ingridients added yet"
				:actions="[{
	        label: '+',
	        function: () => openIngredientPopup()
	      }]"
		  >
		  	<div v-if="products.state.current.ingredients.length > 0" class="cols-1 gap-thin">
			    <CardPosition 
			      v-for="(product, index) in products.state.current.ingredients" 
			      :key="product._id" 
			      :product="product"
			      :array="products.state.current.ingredients"
			      :products="Products"
		        :showPrice="false"
			      @add="(item) => {
					    globals.actions.add(products.state.current.ingredients, item);
					    closeIngredientPopup();
					  }"
					  @update="(item) => {
					    globals.actions.update(products.state.current.ingredients, item, index);
					    closeIngredientPopup();
					  }"
					  @delete="(item) => {
					    globals.actions.delete(products.state.current.ingredients, item, index);
					    closeIngredientPopup();
					  }"
			    />
			  </div>
		  </Block>

		  <Popup 
	      title="Add ingredient" 
	      @close-popup="closeIngredientPopup" 
	      :isPopupOpen="isOpenIngredientPopup"
	      class="bg-white w-min-30r w-max-30r radius-big pd-medium"
	    >
	      <div class="bg-light mn-b-thin h-min-20r h-max-20r o-scroll pd-medium radius-big">
	        <Feed
	          :search="{
	          	class: 'bg-white mn-b-thin'
	          }"
	          :states="{
	            empty: {
	              title: 'No Products Found',
	              description: 'Currently, there are no such products available.'
	            }
	          }"
	          :store="{
	            read: (options) => products.actions.read(options),
	            state: null
	          }"
	          :options="{
	            organization: route.params._id
	          }"
	          v-slot="{ 
	            items 
	          }"
	          class=""
	        >
	          <CardOrderItem
	            v-for="(product, index) in items" :key="product._id"
	            :editable="false" 
	            :product="product" 
	            @click="() => { 
	              let p = { ...product };
	              p.quantity = 1;
	              p.type = 'pcs'
	              globals.actions.add(products.state.current.ingredients,p)
	              closeIngredientPopup();
	            }"
	            class="bg-white pd-thin radius-medium w-100 mn-b-thin"
	          />
	        </Feed>
	      </div>
	    </Popup>


		</div> 

			<Block
  			title="Categories"
  			:actions="[{
	        label: '+',
	        function: () => router.push({
						name: 'Categories Organization', 
						params: { 
							_id: route.params._id
						} 
					})
	      }]"
  		>
				<Checkbox 
					v-for="(category, index) in categories.state.all"
          :label="category.name"
          name="categories"
          :value="category.url"
          class="w-100 mn-t-small bg-white radius-small pd-small"
          :radio="products.state.current.category"
          @update:radio="event => products.state.current.category = event"
        />
			</Block>


			<Block
				title="Parameters"
				placeholder="No parameters added yet"
				:actions="[{
					label: '+',
					function: () => products.state.current.information.push({name: '', value: ''})
				}]"
				class="cols-1 gap-thin"
			>
        <div 
        	class="gap-thin flex-nowrap flex" 
        	v-for="(item, index) in products.state.current.information" 
        	:key="index"
        > 
          <Field
            v-model:field="item.name"
            placeholder="Название параметра"
            class="w-100 bg-white radius-small pd-medium"
          />  
          <Field
            v-model:field="item.value"
            placeholder="Значение параметра"
            class="w-100 bg-white radius-small pd-medium"
          />
          <div class="radius-small h-100 i-big flex-center flex aspect-1x1 bg-red">
	          <IconDelete 
	          	@click="() => products.state.current.information.splice(index, 1)" 
	          	class="i-medium"
	          />
	        </div>
        </div>
			</Block>


			<Block
				title="Localization"
				placeholder="No localizations added yet"
				:actions="[{
					label: '+',
					function: () => products.state.current.localization.push({name: '', value: ''})
				}]"
			>
        <div 
        	class="gap-thin mn-b-thin flex-nowrap flex" 
        	v-for="(item, index) in products.state.current.localization" 
        	:key="index"
        > 
          <Field
            v-model:field="item.name"
            placeholder="Название параметра"
            class="w-100 bg-white radius-small pd-medium"
          />  
          <Field
            v-model:field="item.value"
            placeholder="Значение параметра"
            class="w-100 bg-white radius-small pd-medium"
          />
          <div class="radius-small pd-small flex-center flex aspect-1x1 bg-red">
	          <IconDelete 
	          	@click="() => products.state.current.localization.splice(index, 1)" 
	          	class="i-medium"
	          />
          </div>
        </div>
			</Block>

	
		
		<!-- <Block
	    title="Quantity Discount"
	    class="mn-b-semi"
	    :actions="[{
				label: '+',
				function: () => products.state.current.quantityDiscount.push({name: '', value: ''})
			}]"
	  >
	  	<div 
      	class="gap-thin mn-b-thin flex-nowrap flex" 
      	v-for="(item, index) in products.state.current.quantityDiscount" 
      	:key="index"
      > 
        <Field
          v-model:field="item.name"
          placeholder="Quantity"
          class="w-100 bg-white radius-small pd-medium"
        />  
        <Field
          v-model:field="item.value"
          placeholder="Discount"
          class="w-100 bg-white radius-small pd-medium"
        />
        <div class="radius-small pd-small flex-center flex aspect-1x1 bg-red">
          <IconDelete 
          	@click="() => products.state.current.quantityDiscount.splice(index, 1)" 
          	class="i-medium"
          />
        </div>
      </div>
		</Block> -->

		<!-- <EditModifications
			:product="products.state.current"
		/> -->



		

</div>
	
</template>

<script setup>
	// Import libs
	import { reactive, computed, onMounted, ref } from 'vue'
	import { useRoute, useRouter } from 'vue-router'
	// Import components
	import Breadcrumbs from '@pf/src/components/Breadcrumbs/Breadcrumbs.vue'

	import Block from '@pf/src/components/Block/Block.vue';
	import Tab from "@pf/src/components/Tab/Tab.vue";
	import Field from "@pf/src/components/Field/Field.vue";
	import Checkbox from "@pf/src/components/Checkbox/Checkbox.vue";
	import Button from "@pf/src/components/Button/Button.vue";
	import Popup from "@pf/src/components/Popup/Popup.vue";
	import Feed from "@pf/src/components/Feed/Feed.vue";

	import EditImages           from '@pf/src/components/EditImages/EditImages.vue';

	import IconDelete from '@pf/src/modules/icons/navigation/IconDelete.vue';

 	import CardPosition from '@pf/src/modules/products/components/blocks/CardPosition.vue';
  import CardOrderItem  from '@pf/src/modules/orders/components/blocks/CardOrderItem.vue'


	import EditProductInfo from '@pf/src/modules/products/components/sections/EditProductInfo.vue';
  import EditModifications from '@pf/src/modules/products/components/sections/EditModifications.vue';
  
	// Accessing router and store
	import * as globals from '@pf/src/modules/globals/views/store/globals';
 	import * as products from '@pf/src/modules/products/store/products';
  import * as categories from '@pf/src/modules/products/store/categories';

	const route = useRoute()
	const router = useRouter()

  const Products = ref(products.state.all);
  const isOpenIngredientPopup = ref(false);


  function openIngredientPopup(member) {
	  isOpenIngredientPopup.value = true;
	  // if (typeof member === "number") selectedMember.value = member;
	}

	function closeIngredientPopup() {
	  isOpenIngredientPopup.value = false;
	  // selectedMember.value = null;
	}

  onMounted(async() => { 
	  products.mutations.resetProduct();

  	if (route.params.product) {
		  await products.actions.read({ _id: route.params.product });
	  } 

	  if (!products.state.current.ingredients) {
	  	products.state.current.ingredients = []
	  }

  	// Data prefetching
	 	await categories.actions.fetchCategories()

    try {
      const productsResponse = await products.actions.read({
        organization: route.params._id,
        limit: 100
      });
      Products.value = productsResponse;
    } catch (error) {
      console.error('error');
    }
  })

	function onImagesUpdate(newImages) {
	  products.state.current.images = newImages;
	}
	
	function onSubmit() {
		if (route.params.product) {
			products.actions.update(route.params.product, products.state.current)
		} else {
			products.actions.create(route.params._id, products.state.current)
		}
	}

	function onDelete() {
		products.actions.deleteProduct(products.state.current._id)
	}

	function redirectTo () {
		router.push({
			name: 'Organization', 
			params: { 
				_id: route.params._id
			} 
		})
	}
</script>

<style lang="scss">
	.main-photo {
		max-width: 50%;
		img { width: 100%; }
	}
</style>
