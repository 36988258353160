<template>
  <div class="pd-thin">
    <header class="mn-b-medium flex-v-center flex-nowrap flex">
      <h2 class="mn-r-medium">Inventory</h2>
      <button 
        @click="$router.push({
          name: 'LeftoverAdd'
        })" 
        class="radius-100 i-big hover-scale-1 cursor-pointer t-white bg-second">
          +
      </button>
    </header>
    
    <section class="mn-b-semi pd-medium bg-light radius-big">
      <p class="mn-b-thin">Balance:</p>
      <h3>{{formatPrice(totalPrice)}}</h3>
    </section>

    <!-- <h3>Low Stock</h3>

    <section class="mn-b-semi pd-medium bg-light radius-big">
      <div>
        Name: Code Red
        Left: 3
        <button>add</button>
      </div>
      <div>
        Name: Code Red
        Left: 3
        <button>add</button>
      </div>
      <div>
        Name: Code Red
        Left: 3
        <button>add</button>
      </div>
    </section> -->

    <Tab 
      v-model:selected="tab"
      :tabs="[
        { name: 'All',        value: 'all' },
        { name: 'Stock-In',   value: 'stock-in' },
        { name: 'Stock-Out',  value: 'stock-out' }
      ]"
      class="mn-b-small o-hidden h5 radius-big bg-light"
    />

    <Feed
      :search="true"
      :states="{
        empty: {
          title: 'No Leftovers Found',
          description: 'Currently, there are no leftovers available.'
        }
      }"
      :store="{
        read: (options) => leftovers.actions.read(options)
      }"
      :options="{
        limit: 15,
        owner: route.params._id,
        ...(tab !== 'all' && { type: tab })
      }"
      v-slot="{ 
        items 
      }"
    >
      <CardLeftover 
        v-for="leftover in items" 
        :leftover="leftover"
        class="bg-light pd-small mn-b-thin radius-small"
      />
    </Feed>
  </div>
</template>


<script setup>
  import { computed, onMounted, reactive, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  import Tab      from '@pf/src/components/Tab/Tab.vue'
  import Feed from '@pf/src/components/Feed/Feed.vue'
  import CardLeftover from '../blocks/CardLeftover.vue'

  import * as leftovers from '@pf/src/modules/products/store/leftovers' // Убедитесь, что путь к вашему файлу стора верный

  const route = useRoute()
  const router = useRouter()

  // Tab logic
  const tab = ref(route.query.tab ? route.query.tab : 'all')
  // await leftovers.actions.read()
  const totalPrice = computed(() => {
    return leftovers.state.all.reduce((totalSum, item) => {
      const itemTotal = item.positions.reduce((sum, position) => {
        return sum + position.price;
      }, 0);

      // Adjust the sum based on the type of transaction
      if (item.type === 'stock-in') {
        // If it's a stock-in, subtract the itemTotal from the totalSum
        return totalSum - itemTotal;
      } else if (item.type === 'stock-out') {
        // If it's a stock-out, add the itemTotal to the totalSum
        return totalSum + itemTotal;
      } else {
        // If the item type is not specified or is different, do not adjust the totalSum
        return totalSum;
      }
    }, 0);
  });

</script>

<style lang="scss">

.round-stat {
  padding: 1rem;
  border-radius: 5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.1);

  &-tab {
    width: 2rem;
    height: 2rem;
  }
}



.order-card {
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 8px 8px -8px rgb(36 36 36 / 10%);
  .order-status {
    color: black;
    background: #EEF2F6; 
    width: 100%;
    padding: 0.75rem 1.5rem;
    margin: 0;
  }

  .new {
    color: white;
    background: #00ff88;
  }

  .block {
    border-radius: 0;
    border: 0;
  }
}

  
</style>
