<template>
  <div class="flex-v-center flex">
    <h4 class="mn-r-auto">{{category.name}}</h4>

    <router-link 
    	:to="{
				name: 'CategoryEdit', 
				params: { 
					_id: route.params._id,
					category: category.url
				} 
			}"
    	class="mn-r-medium nav-link"
    >
    	Edit
   	</router-link>
    <a 
    	class="nav-link" 
    	@click="deleteCategory(category)"
    >
  		Delete
  	</a>
   </div>
</template>


<script setup>
import { toRef, onMounted, defineEmits } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  category: Object,
});

const emits = defineEmits(['delete']);

const route = useRoute();
const router = useRouter();

const deleteCategory = (category) => {
  emits('delete', category);
};

</script>


<style lang="scss">
.categories-card {

}
</style>


