<template>
  <article v-if="event" class="h-min-100 pd-thin">
  	<div class="mn-b-thin radius-small w-100 h-10r bg-light flex-center flex-column flex">
     	<UploadImage 
        v-model:photo="event.cover"
        :uploadPath="'specials'"
        class="h-4r w-4r aspect-1x1 o-hidden mn-b-thin radius-extra" 
      />
      <h4>Upload Event Cover</h4>
    </div>

  	<Field 
      v-model:field="event.name" 
      placeholder="Event name" 
      class="mn-b-thin w-100 bg-light pd-medium radius-small" 
    />
    <Field 
      v-model:field="event.description" 
      type="textfield"  
      placeholder="Enter short description" 
      class="mn-b-semi w-100 bg-light pd-medium radius-small" 
    />

    <h3 class="mn-b-small">Date</h3>
    <VueDatePicker v-model="date"  range class="z-index-3 radius-small mn-b-semi bg-light" />

   <!--    <Calendar
        v-model:date="date"
        :locale="$i18n.locale"
        :allowRange="false"
        class="radius-semi w-100 o-hidden bg-light"
      /> -->

    <h3
      v-if="(auth.state.access.roles) && (auth.state.access.roles.includes('ROLE_MODERATOR') || auth.state.access.roles.includes('ROLE_ADMIN'))"
      class="mn-b-small"
    >
      Tickets
    </h3>

  
    <h3
      v-if="!route.params.url && (auth.state.access.roles) && (auth.state.access.roles.includes('ROLE_MODERATOR') || auth.state.access.roles.includes('ROLE_ADMIN'))"
      class="mn-b-small"
    >
        Special Details
    </h3>


    <Checkbox 
      v-if="!route.params.url && (auth.state.access.roles) && (auth.state.access.roles.includes('ROLE_MODERATOR') || auth.state.access.roles.includes('ROLE_ADMIN'))"
      :label="'Special'"
      :radio="event.special"
      @update:radio="updateEvent => event.special = !event.special"
      class="mn-b-semi w-100 bg-black t-white pd-medium radius-small"
    />

    <div 
      v-if="event.special"
      class="mn-b-semi"
    >
      <h4
        class="mn-b-thin"
      >
          Hero Section
      </h4>

      <Field 
        v-model:field="event.specialData.subtitle" 
        placeholder="Event subtitle" 
        class="mn-b-thin w-100 bg-light pd-medium radius-small" 
      />

      <EditImages 
        :images="event.specialData.logos"
        :uploadPath="'/specials'" 
        :text="{
          title: 'Drag & Drop Your Logos Here',
          subtitle: 'Files supported: JPG, PNG, GIF'
        }"
        :options="{
          showTitle: true,
          showText: false
        }"
        @update:images="(newImages) => event.specialData.logos = newImages"  
        class="mn-b-thin bg-light radius-small"
      />
       <h5
        class="mn-b-thin"
      >
          Video in Hero Section
      </h5>
      <p>Need to upload in webm format because of needs of optimization</p>
      <Upload 
        v-model:field="event.specialData.video"
        :placeholder="'Upload video'" 
        @file-change="(videoURL) => event.specialData.video = videoURL"
        uploadPath="specials"
        type="file"   
        class="
          mn-b-thin w-100 bg-light pd-medium radius-small t-black
        "
      />

      <h5
        class="mn-b-thin"
      >
        Ticket
      </h5>

      <div 
        class="mn-b-small gap-small cols-2-fit-content" 
      > 
        <UploadImage 
          v-model:photo="event.specialData.ticketImage"
          :uploadPath="'/specials'"
          class="aspect-1x1 w-4r o-hidden radius-extra" 
        />

        <div class="gap-thin flex-nowrap flex w-100">
          <Field 
            v-model:field="event.specialData.ticketPrice" 
            type="number"
            placeholder="Ticket price in baht" 
            class="mn-b-thin w-100 bg-light pd-medium radius-small" 
          />

          <Field
            v-model:field="event.specialData.ticketText"
            placeholder="Promotion text for ticket in hero  "
            class="w-100  bg-light radius-small pd-medium"
          />

           <Field
            v-model:field="event.specialData.ticketTextButton"
            placeholder="Text on 'Buy Ticket' button  "
            class="w-100  bg-light radius-small pd-medium"
          />

          
        </div>
      </div>

      <hr class="bg-black-transp-10 mn-b-semi mn-t-semi d-block">

      <h4
        class="mn-b-thin"
      >
          Guest Section
      </h4>

      <Field 
        v-model:field="event.specialData.guestTitle" 
        placeholder="Title for Main Guest section" 
        class="mn-b-thin w-100 bg-light pd-medium radius-small" 
      />

      <Field 
        v-model:field="event.specialData.guestDescription" 
        placeholder="Description for Main Guest section" 
        class="mn-b-thin w-100 bg-light pd-medium radius-small" 
      />

      <div class="gap-thin cols-3">
        
        <Field 
          v-model:field="event.specialData.guestFacebook" 
          placeholder="Facebook of Main Guest" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />

        <Field 
          v-model:field="event.specialData.guestInstagram" 
          placeholder="Instagram of Main Guest" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />

        <Field 
          v-model:field="event.specialData.guestTwitter" 
          placeholder="Twitter of Main Guest" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />


        <Field 
          v-model:field="event.specialData.guestTelegram" 
          placeholder="Telegram of Main Guest" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />

        <Field 
          v-model:field="event.specialData.guestSoundcloud" 
          placeholder="Link to Main Guest soundcloud track" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />

        <Field 
          v-model:field="event.specialData.guestSpotify" 
          placeholder="Link to Main guest spotify track" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />

        <Field 
          v-model:field="event.specialData.guestYoutube" 
          placeholder="Link to Main guest youtube video" 
          class="mn-b-thin w-100 bg-light pd-medium radius-small" 
        />
      </div>

      <h5
        class="mn-b-thin"
      >
          Video in Guests Section
      </h5>

      <Upload 
        v-model:field="event.specialData.guestVideo"
        :placeholder="'Upload video'" 
        @file-change="(videoURL) => event.specialData.guestVideo = videoURL"
        uploadPath="/specials"
        type="file"   
        class="
          mn-b-thin w-100 bg-light pd-medium radius-small t-black
        "
      />

      <Block
        title="Guests"
        placeholder="No guests added yet"
        :actions="[{
          label: '+',
          function: () => event.specialData.guests.push({name: null, description: null, photo: null, main: false})
        }]"
        class="cols-1 mn-b-thin  t-black gap-thin"
      >
        <div 
          class="mn-b-small gap-small cols-2-fit-content" 
          v-for="(item, index) in event.specialData.guests" 
          :key="index"
        > 
          <UploadImage 
            v-model:photo="item.photo"
            :uploadPath="'/specials'"
            class="aspect-1x1 w-8r o-hidden radius-extra" 
          />

          <div class="gap-thin flex-nowrap flex w-100">
            <div class="w-100">
              <Field
                v-model:field="item.name"
                placeholder="Name"
                class="w-100 mn-b-thin bg-white radius-small pd-medium"
              />  
              <Field
                v-model:field="item.description"
                placeholder="Description"
                class="w-100 mn-b-thin bg-white radius-small pd-medium"
              />
            </div>
            <div class="radius-small h-100 i-big flex-center flex aspect-1x1 bg-red">
              <IconDelete 
                @click="() => event.specialData.guests.splice(index, 1)" 
                class="i-medium"
              />
            </div>
          </div>
        </div>
      </Block>

      <hr class="bg-black-transp-10 mn-b-semi mn-t-semi d-block">

      <h4
        class="mn-b-thin"
      >
        Lineup Section
      </h4>

      <Block
        title="Line Up"
        placeholder="No line ups added yet"
        :actions="[{
          label: '+',
          function: () => event.specialData.lineup.push({name: null, description: null, photo: null, main: false})
        }]"
        class="cols-1 t-black gap-thin"
      >
        <div 
          class="mn-b-thin gap-small flex-nowrap flex" 
          v-for="(item, index) in event.specialData.lineup" 
          :key="index"
        > 
          <Field
            v-model:field="item.name"
            placeholder="Name"
            class="w-100  bg-light radius-small pd-medium"
          />  
          <Field
            v-model:field="item.time"
            placeholder="Time"
            class="w-100  bg-light radius-small pd-medium"
          />
          <div class="radius-small h-100 i-big flex-center flex aspect-1x1 bg-red">
            <IconDelete 
              @click="() => event.specialData.lineup.splice(index, 1)" 
              class="i-medium"
            />
          </div>
        </div>
      </Block>

      <h5
        class="mn-b-thin"
      >
        Background in Lineup Section
      </h5>

      <Upload 
        v-model:field="event.specialData.lineupBackground"
        :placeholder="'Upload image'" 
        @file-change="(videoURL) => event.specialData.lineupBackground = videoURL"
        uploadPath="/specials"
        type="file"   
        class="
          mn-b-thin w-100 bg-light pd-medium radius-small t-black
        "
      />

      <hr class="bg-black-transp-10 mn-b-semi mn-t-semi d-block">

      <h4
        class="mn-b-thin"
      >
        Previous Party Section
      </h4>

       <h5
        class="mn-b-thin"
      >
        Video in Previous Partys Section
      </h5>

      <Field
        v-model:field="event.specialData.previousTitle"
        placeholder="Title"
        :value="event.specialData.previousDescription || 'Explore our exclusive party archive'"
        class="w-100  bg-light radius-small pd-medium"
      />  
      <Field
        v-model:field="event.specialData.previousDescription"
        label="Description"
        :value="event.specialData.previousDescription || 'Experience the ultimate party vibe at The Commune! Elevate your evening with distinctive and exhilarating activities, ensuring an unforgettable time. Dive into the excitement and make every moment special.'"
        class="w-100  bg-light radius-small pd-medium"
      />  
      <Field
        v-model:field="event.specialData.previousLinkText"
        label="Button"
        :value="event.specialData.previousLinkText || 'Check In Instagram'"
        class="w-100  bg-light radius-small pd-medium"
      />  
      <Field
        v-model:field="event.specialData.previousLink"
        label="Link"
        :value="event.specialData.previousLink || 'https://www.instagram.com/thecommune.phuket/'"
        class="w-100  bg-light radius-small pd-medium"
      />
      
      <Upload 
        v-model:field="event.specialData.previousVideo"
        :placeholder="'Upload image'" 
        @file-change="(videoURL) => event.specialData.previousVideo = videoURL"
        uploadPath="/specials"
        type="file"   
        class="
          mn-b-thin w-100 bg-light pd-medium radius-small t-black
        "
      />
    </div>

    <h3 class="mn-b-small">Description</h3>
		<section v-if="event" class="pd-b-extra w-100 bg-light pd-big radius-big">
      <Constructor 
        :content="event.content"
        @update="update => event.content = update"
      />
    </section>

    <section v-if="event" class="pd-thin pos-sticky pos-l-0 pos-b-0 w-100 ">
      <div class="pd-thin radius-big  bg-main w-100 flex-nowrap flex">
        <a v-if="route.params.url" @click="onDelete()" class="mn-r-auto bg-red t-white t-black button">Delete</a>
        <a @click="onDrafts()" class="mn-l-auto bg-white t-black button">To Drafts</a>
        <a @click="openPublicationPopup()" class="mn-l-thin bg-black t-white button">Publish</a>
      </div>
    </section>

    <Popup 
      title="Final Touches" 
      @close-popup="closePublicationPopup" 
      :isPopupOpen="isPublicationPopup"
      class="w-m-33r t-left pd-big bg-white radius-big"
    > 
      <BlockTags
        @tags-changed="newTags => event.tags = newTags"
        :tags="event.tags"
        class="mn-b-small"
      />

      <h5 class="mn-b-thin">Add to public</h5>
      <Card
        v-if="selectedOrganization"
        :photo="selectedOrganization.profile?.photo"
        :title="selectedOrganization.profile?.name"
        @click="() => { 
          selectedOrganization = null
        }"
        class="h-4r w-100 bg-light pd-thin radius-medium  mn-b-thin"
      />

      <section v-else class="mn-b-thin h-25r o-x-hidden o-y-scroll bg-light radius-big pd-small ">
        <Feed
          :showLoadMore="false" 
          :search="{
            placeholder: 'Search organization...',
            class: 'bg-white mn-b-thin'
          }"
          :states="{
            empty: {
              title: 'No organizations Found',
              description: 'Currently, there are no such organizations available.'
            }
          }"
          :store="{
            read: (options) => organizations.actions.read(options),
            state: null
          }"
          :options="{
            user: auth.state.user._id,
            postable: auth.state.user._id
          }"
          v-slot="{ 
            items 
          }"
        >
          <Card
            v-for="(organization, index) in items" 
            v-memo="[organization._id, organization.profile.name]"
            :photo="organization.profile?.photo"
            :title="organization.profile?.name"
            @click="() => { 
              selectedOrganization = organization
            }"
            class="h-4r bg-white pd-thin radius-medium w-100 mn-b-thin"
          />
        </Feed>
      </section>

      
      <h5 v-if="selectedOrganization" class="mn-b-thin">Options:</h5>
      <div v-if="selectedOrganization" class="mn-b-medium br-grey-transp-25 br-2px br-solid pd-small radius-big">
        <Checkbox 
          :label="'Hide Author'"
          :radio="event.creator.hidden"
          @update:radio="updateEvent => event.creator.hidden = updateEvent"
          name="prices"
          class="w-100"
        />
      </div>
      <Button :submit="onSubmit" :callback="redirectTo" class="w-100 bg-black t-white">Publish</Button>
    </Popup>
  </article>
</template>

<script setup>
import Textarea from '@pf/src/modules/constructor/components/elements/Textarea.vue';
import Constructor from '@pf/src/modules/constructor/components/sections/Constructor.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'



import Calendar from '@pf/src/components/DatePicker/Calendar.vue'

import Block from '@pf/src/components/Block/Block.vue';
import Popup from '@pf/src/components/Popup/Popup.vue';
import Field from '@pf/src/components/Field/Field.vue'
import BlockTags from '@pf/src/components/FieldTags/BlockTags.vue';
import Checkbox from '@pf/src/components/Checkbox/Checkbox.vue';
import SelectMulti from '@pf/src/components/SelectMulti/SelectMulti.vue';
import Upload  from '@pf/src/components/Upload/Upload.vue';
import UploadImage from '@pf/src/components/UploadImage/UploadImage.vue';
import EditImages from '@pf/src/components/EditImages/EditImages.vue';
import Feed from '@pf/src/components/Feed/Feed.vue'
import Button from '@pf/src/components/Button/Button.vue';   

import Card from '@pf/src/modules/globals/views/components/blocks/Card.vue';

import IconDelete from '@pf/src/modules/icons/navigation/IconDelete.vue';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import * as events from '@pf/src/modules/events/store/events.js';
import * as auth from '@pf/src/modules/auth/views/store/auth';
import * as organizations from '@pf/src/modules/organizations/store/organizations';

const route = useRoute();
const router = useRouter();

let event = ref(null);
let publics = ref(null);

const selectedTags = ref([]);
const selectedOrganization = ref(null);


const date = ref(new Date);
const customPosition = () => ({ top: 0, left: 0 });


onMounted(async () =>{
  
  if (route.params.url) {
    const data = await events.read({ user: auth.state.user._id, url: route.params.url });

    event.value = data.pop();

    console.log(event.value)

    const startDate = event.value.date.start;
    const endDate = event.value.date.end;

    date.value = [startDate, endDate];

  } else {
    events.clean();
    event.value = events.state.current;

    const startDate = new Date();
    const endDate = new Date();
    date.value = [startDate, endDate];
  }

  if (!event.value.owner) event.value.owner = {
    target: auth.state.user._id,
    type: 'user'
  }

  if (!event.value.creator) event.value.creator = {
    target: auth.state.user._id,
    type: 'user',
    hidden: false
  }

  if (event.value.owner.type === 'organization') selectedOrganization.value = {
    _id: event.value.owner.target._id,
    name: event.value.owner.target.profile.name,
    photo: event.value.owner.target.profile.photo
  }
})

const isPublicationPopup = ref(false)

function openPublicationPopup() {
  isPublicationPopup.value = true;
}
function closePublicationPopup() {
  isPublicationPopup.value = false;
}

function onDrafts() {

  if (selectedTags.value.length > 0) selectedTags.value.map(tag => (tag.text))

  event.value.status = "draft"
  
  event.value.date = {
    start: date.value[0],
    end: date.value[1]
  }

  if (route.params.url) {
    events.update(event.value)
      .then(response => {
        router.push({ name: 'Event', params: { url: response.url } });
      })
      .catch(error => {
        console.error(error);
      })
  } else {
    events.create(event.value)
      .then(response => {
        router.push({ name: 'Event', params: { url: response.url } });
      })
      .catch(error => {
        console.error(error);
      })
  }
}

function onSubmit() {

  if (selectedOrganization.value) event.value.owner = {
    target: selectedOrganization.value._id,
    type: 'organization'
  }

  if (!selectedOrganization.value) event.value.creator.hidden = false
  if (!selectedOrganization.value) event.value.organization = event.value.creator

  event.value.status = "published"

  event.value.date = {
    start: date.value[0],
    end: date.value[1]
  }

  console.log(date.value)
  if (route.params.url) {
    console.log(event.value)
    events.update(event.value)
      .then(response => {
        router.push({ name: 'Event', params: { url: response.url } });
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    events.create(event.value)
      .then(response => {
        router.push({ name: 'Event', params: { url: response.url } });
      })
      .catch(error => {
        console.log(error);
      });
  }
}

function onDelete() {
  if (confirm('Are you sure you want to delete this event?')) {
    events.remove(event.value._id)
      .then(response => {
        router.push({ name: 'User Events', params: { _id: event.value.creator.target._id } });
      })
      .catch(error => {
        console.error(error);
      })
  }
}
</script>

<style lang="scss">
  .dp__input {
    border: 0;
    padding: var(--medium);
    padding-left: 3rem;
    background: rgb(var(--light));
    border-radius: var(--small);
  }
</style>