<template>
  <div>
    <ul 
      class="flex-column flex-wrap block"
    >
      <span 
        v-if="order.positions.length === 0" 
        class="w-100"
      >
        No positions added yet
      </span> 

      <li 
        v-for="(product, index) in order.positions" 
        :key="product._id" 
        class="w-100 flex-center flex-nowrap flex"
      >
        <span 
          class="mn-r-regular w-100"
        >
          {{ product.name }}
        </span>

        <Field 
          v-model:field="product.price"
          type="number"   
          label="Price" 
          :symbol="returnCurrency()"
          class="bg-light pd-medium radius-small" 
        />

        <Field 
          v-model:field="product.quantity" 
          type="number"  
          label="Quantity" 
          class="bg-light pd-medium radius-small" 
          :symbol="product.type"
        />
       
        <a
          class="button" 
          @click="deleteProduct(index)"
        >
          Delete
        </a>
        <!-- <hr v-if="index < order.positions.length - 1" class="mn-t-small mn-b-small w-100 "> -->
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Field from '@pf/src/components/Field/Field.vue';

import * as leftovers from '@pf/src/modules/products/store/leftovers';

const router = useRouter();
const route = useRoute();

const props = defineProps({
  order: Object
});

function deleteProduct(index) {
  leftovers.actions.deleteItemFromLeftover(props.order.positions[index]);
}

function updateProductQuantity(index, event) {
  props.order.positions[index].quantity = event.target.value;
}

function updateProductPrice(index, event) {
  props.order.positions[index].price = event.target.value;
}
</script>
  

<style lang="scss" >
.vue-select {
  background: #f7f7f7;
  border-radius: 0.5rem;
  border: 0;
  height: 3rem;
    padding-left: 1rem;
   
    z-index: 4;
  .vue-input {
    input { 
       font-size: 1.125rem;
    font-weight: 300;
      background: #f7f7f7; }
  }
}
</style>


