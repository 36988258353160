import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

export function createEventRoutes(prefix = '', options = {}) {
  const nameWithPrefix = (name) => prefix ? `${prefix}${name}` : name;

  const routes = {
    path: options.basePath || 'events',
    meta: {
      title: {
        en: 'Events',
        ru: 'События'
      },
      title_hide: true
    },
    children: [
      {
        path: '',
        name: nameWithPrefix('Events'),
        meta: {
          title: {
            en: 'Events',
            ru: 'События'
          },
          title_hide: true
        },
        component: () => import(/* webpackChunkName: 'Events' */ '../components/pages/Events.vue')
      },
      {
        path: 'backoffice',
        name: nameWithPrefix('Events Backoffice'),
        meta: {
          title: {
            en: 'Manage Events',
            ru: 'Управление Событиями'
          },
          authorize: options.backofficeAuthorize || []
        },
        component: () => import(/* webpackChunkName: 'Events' */ '../components/pages/EventsBackoffice.vue')
      },
      {
        path: 'create',
        name: nameWithPrefix('Create Event'),
        meta: {
          title: {
            en: 'Create Event',
            ru: 'Создать Событие'
          },
          authorize: options.createEventAuthorize || []
        },
        component: () => import(/* webpackChunkName: 'Events' */ '../components/pages/EditEvent.vue')
      },
      {
        path: ':url',
        name: nameWithPrefix('Event'),
        meta: {
          title: {
            en: 'Event',
            ru: 'Событие'
          }
        },
        component: () => import(/* webpackChunkName: 'Events' */ '../components/pages/Event.vue')
      },
      {
        path: ':url/edit',
        name: nameWithPrefix('Edit Event'),
        meta: {
          title: {
            en: 'Edit Event',
            ru: 'Редактировать Событие'
          },
          authorize: options.editEventAuthorize || []
        },
        component: () => import(/* webpackChunkName: 'Events' */ '../components/pages/EditEvent.vue')
      },
      {
        path: ':url/edit/tickets',
        name: nameWithPrefix('Edit Event Tickets'),
        meta: {
          title: {
            en: 'Event Tickets',
            ru: 'Билеты События'
          },
          authorize: options.editTicketsAuthorize || []
        },
        component: () => import(/* webpackChunkName: 'Events' */ '../components/pages/EditEventTickets.vue')
      }
    ]
  }

  return routes;
}

// Example usage:
// const eventsRoutes = createEventRoutes('Admin', { basePath: 'admin/events' });
// export default eventsRoutes;