<template>
  <div class="embla" ref="emblaNode">
    <div class="embla__container">
      <div class="embla__slide pd-nano" v-for="(entity, key, index) in entitiesState" :key="key">
        <transition name="fade" mode="out-in" appear>
          <slot
            :item="entity"
            :user="user"
          ></slot>
        </transition>
      </div>

    </div>
  </div>
</template>

<script setup >
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import emblaCarouselVue from 'embla-carousel-vue'
import Autoplay from 'embla-carousel-autoplay'

import Loader from '@pf/src/components/Loader/Loader.vue'

// Props for customization
const props = defineProps({
  store: Object,
  options: Object,
  text: Object,
  user: Object
});

const router = useRouter()

const entitiesState = ref([])
const isLoading = ref(true);
const selectedIndex = ref(0);

const autoplayOptions = {
  delay: 2000,
  jump: false,
  stopOnInteraction: false,
  stopOnMouseEnter: false,
  stopOnFocusIn: true,
  stopOnLastSnap: false,
  rootNode: (emblaRoot) => emblaRoot.parentElement
}

const [emblaNode, emblaApi] = emblaCarouselVue(
  { loop: true }, 
  [
    Autoplay(autoplayOptions)
  ]
)

const scrollTo = (index) => emblaApi.value && emblaApi.value.scrollTo(index);
const onSelect = (embla) => selectedIndex.value = emblaApi.value.selectedScrollSnap();
// const onInit = (embla) =>  scrollSnaps.value = emblaApi.value.scrollSnapList();

onMounted(async() => {
  entitiesState.value = await props.store.read(props.options);

  isLoading.value = false;

  onSelect(emblaApi);

  // emblaApi.value.on('reInit', onInit);
  emblaApi.value.on('reInit', onSelect);
  emblaApi.value.on('select', onSelect);
});
</script>
