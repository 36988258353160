<template>
	<div 
		class="
			radius-big 
			cols-1
			o-hidden
			pos-relative
		"
		:class="
			product.information[2] 
			&& product.information[2].value === 'AAAA' 
			? 'bg-black t-white' 
			: 'bg-white'
		"
	>
		<router-link
			v-if="user && user.roles && (user.roles.includes('ROLE_MODERATOR') || user.roles.includes('ROLE_ADMIN'))"
      :to="{
        name: 'ProductEdit', 
        params: {
          _id: $route.params.organization,
          product: product._id
        }
      }" 
      class="
      	z-index-2
        cursor-pointer 
        pos-absolute pos-t-regular pos-r-regular
        radius-extra pd-thin bg-second
      "
    >
      <IconEdit
        class="i-regular"
        classes="fill-white"
      />
    </router-link>

		<!-- Product Image Sqaure -->


		<div 
			class="o-hidden w-100 pos-relative aspect-1x1"
			:class="
				product.information[2] 
				&& product.information[2].value === 'AAAA' 
				? 'bg-black' 
				: 'bg-white'
			"
		>

			<!-- Image Wrappers  -->
			<img loading="lazy" 
				v-if="product.images[0]" 
				:src="(FILE_SERVER_URL || '') + product.images[0].split('/').slice(0, -1).join('/') + '/thumbnail_' + product.images[0].split('/').pop()"
				class="pos-absolute object-fit-cover h-max-100 w-100"
			>

			<PlaceholderImage
				v-else
				class="pos-absolute h-100 w-100"
			/>
			<span v-if="product.quantity <= 0" class="pos-absolute bg-light t-black pd-thin radius-big p-small t-medium pos-t-medium pos-l-medium">Out of Stock</span>
			<THC v-if="product.information?.length > 0 && product.information[1]?.name === 'THC'" :product="product" />
		</div>

		<!-- Product Info Start -->
		<div  class="pd-medium t-left product-card-content">
			<!-- Detail -->
			<p 
				v-if="product.information.length > 0"  
				class="t-semi h6"
				:class="
					product.information[2] 
					&& product.information[2].value === 'AAAA' 
					? 't-violet' 
					: 't-main'
				" 
			>
				{{product.information[0].value}}
			</p>
			<!-- Name -->
			<p class="mn-b-thin t-semi h4">
				{{product.name}}
			</p>

			<p 
				v-if="product.description && !product.localization" 
				class="mn-b-small t-transp p-small"
			>
				{{product.description.substring(0, 99)}}...
			</p>

			<!-- <p v-if="product.localization && product.localization.length > 1" class="mn-b-small t-transp p-small">
				<span v-if="$i18n.locale === 'en'">{{product.localization[0].text.substring(0, 99)}}...</span>
				<span v-if="$i18n.locale === 'ru'">{{product.localization[1].text.substring(0, 99)}}...</span>
			</p> -->
			
			<Price 
				:product="product" 
				size="small" 
				:class="
					product.information[2] 
					&& product.information[2].value === 'AAAA' 
					? 'bg-third t-white' 
					: ''
				" 
			/>
		</div>

	</div>

</template>


<script setup="props">
	import {computed } from 'vue'
	import { useI18n }    from 'vue-i18n'

	import IconEdit from '@pf/src/modules/icons/navigation/IconEdit.vue'

	import THC from '@pf/src/modules/products/components/elements/THC.vue'
	import Price from '@pf/src/modules/products/components/elements/Price.vue'

	import PlaceholderImage from '@pf/src/modules/icons/placeholders/PlaceholderImage.vue'

	const props = defineProps({
		product: {
			type: Object
		},
		user: {
			type: [Object, String] 
		},
		type: {
			type: Object
		}
	})

  const { t } = useI18n()
</script>

<style lang="scss">

</style>


