<template>
  <div class="pd-thin">
    <header class="mn-b-medium gap-small flex-v-center flex-nowrap flex">
      <h2 class="">{{route.params.leftover ? 'Edit ' : 'Add ' }} Inventory</h2>

      <Select 
        v-model:select="leftovers.state.current.type" 
        :options="[
          'stock-in',
          'stock-out'
        ]"
        :placeholder="'Select type'"
        class="bg-main t-black w-max pd-small t-semi p-big uppercase radius-small"
      />
    </header>
    
    <Popup 
      title="Add position" 
      @close-popup="closeProductsPopup" 
      :isPopupOpen="isOpenProductsPopup"
      class="bg-white w-min-30r w-max-30r radius-big pd-medium"
    >
      <div class="bg-light mn-b-thin h-min-20r h-max-20r o-scroll pd-medium radius-big">
        <Feed
          :search="true"
          :states="{
            empty: {
              title: 'No Products Found',
              description: 'Currently, there are no such products available.'
            }
          }"
          :store="{
            read: (options) => products.actions.read(options),
            state: null
          }"
          :options="{
            organization: route.params._id
          }"
          v-slot="{ 
            items 
          }"
          class=""
        >
          <CardOrderItem
            v-for="(product, index) in items" :key="product._id"
            :editable="false" 
            :product="product" 
            @click="() => { 
              let p = { ...product };
              p.quantity = 1;
              p.type = 'pcs'
              globals.actions.add(leftovers.state.current.positions, p) 
              closeProductsPopup();
            }"
            class="bg-white pd-thin radius-medium w-100 mn-b-thin"
          />
        </Feed>
      </div>
    </Popup>

    <Block
      title="Invetory Sheet"
      :actions="[{
        label: '+',
        function: () => openProductsPopup()
      }]"
      placeholder="No positions added yet"
      class="mn-b-semi"
    >
      <CardPosition 
        v-for="(product, index) in leftovers.state.current.positions" 
        :key="product._id" 
        :product="product"
        :array="leftovers.state.current.positions"
        :products="Products"
        @update="(item) => globals.actions.update(
          leftovers.state.current.positions,
          item,
          index
        )"
        @delete="(item) => globals.actions.delete(
          leftovers.state.current.positions,
          item,
          index
        )"
        class="mn-b-small radius-medium bg-white pd-small"
      />
    </Block>

    <Block
      title="Comment"
      class="mn-b-semi"
    >
      <Field
        v-model:field="leftovers.state.current.comment"
        label="Comment"
        type="textarea"
        class="w-100 bg-white radius-small pd-medium"
      />  

    </Block>
   
    <Block class="">
      <section class="gap-thin flex-v-center flex-nojustify flex">
        
        <h3 class="mn-r-auto">
          In total: {{formatPrice(totalPrice)}}
        </h3>

        <a 
          @click="onSubmit()" 
          class="bg-main button"
        >
          Save
        </a> 

        <a 
          class="t-white bg-second button"
          @click="onReset()"
        >
          Reset
        </a>

        <a 
          v-if="route.params.leftover" 
          @click="onDelete()" 
          class="t-white bg-red button"
        >
          Delete
        </a>
      </section>
    </Block>
   </div>
</template>


<script setup>
  import Block from '@pf/src/components/Block/Block.vue';

  import Field from '@pf/src/components/Field/Field.vue';
  import Feed from '@pf/src/components/Feed/Feed.vue'
  import Select from '@pf/src/components/Select/Select.vue';
  import Popup from '@pf/src/components/Popup/Popup.vue';
  import SelectMulti from '@pf/src/components/SelectMulti/SelectMulti.vue';

  import CardPosition from '@pf/src/modules/products/components/blocks/CardPosition.vue';
  import CardOrderItem  from '@pf/src/modules/orders/components/blocks/CardOrderItem.vue'

  import { computed, onMounted, ref, reactive } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
 
  import * as globals from '@pf/src/modules/globals/views/store/globals';
  import * as auth from '@pf/src/modules/auth/views/store/auth';
  import * as leftovers from '@pf/src/modules/products/store/leftovers';
  import * as products from '@pf/src/modules/products/store/products';

  const route = useRoute();
  const router = useRouter();
  
  const Products = ref(products.state.all); // Обновите это значение соответствующим образом
  const productNew = ref(null);

  const totalPrice = computed(() => {
    return Number(leftovers.state.current.positions.reduce((sum, position) => {
      return Number(sum) + Number(position.price);
    }, 0));
  });

  const isOpenProductsPopup = ref(false);

  function openProductsPopup() {
    isOpenProductsPopup.value = true;
  }

  function closeProductsPopup() {
    isOpenProductsPopup.value = false;
  }

  onMounted(async () => {
    if (route.params.leftover) {
      try {
        await leftovers.actions.fetch(route.params.leftover);
      } catch (error) {
        console.error('error');
      }
    } else {
      leftovers.state.current = { type: "", order: "", positions: [] };
    }

    try {
      const productsResponse = await products.actions.read({
        organization: route.params._id,
        limit: 100
      });
      Products.value = productsResponse;
    } catch (error) {
      console.error('error');
    }
  });

  function onReset() {
    if (route.params.leftover) {
      leftovers.actions.fetch(route.params.leftover);
    } else {
      leftovers.state.current = { type: "", order: "", positions: [] };
    }
  }

  function onSubmit() {
    leftovers.state.current.organization = route.params._id;

    if (route.params.leftover) {
      leftovers.actions.update(leftovers.state.current).then(
        () => router.push({ name: 'Leftovers', params: {_id: route.params._id} }),
        (error) => console.error('error')
      );
    } else {
      leftovers.state.current.creator = {
        type: 'user',
        target: auth.state.user._id
      }
      leftovers.state.current.owner = {
        type: 'organization',
        target: route.params._id
      }

      console.log('leftover state is', leftovers.state.current)
      leftovers.actions.create(leftovers.state.current).then(
        () => router.push({ name: 'Leftovers', params: {_id: route.params._id} }),
        (error) => console.error('error')
      );
    }
  }

  function onDelete() {
    leftovers.actions.delete(route.params.leftover).then(
      () => router.push({ name: 'Leftovers', params: {_id: route.params._id} }),
      (error) => console.error('error')
    );
  }
</script>


<style lang="scss">

.round-stat {
  padding: 1rem;
  border-radius: 5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0,0,0,0.1);

  &-tab {
    width: 2rem;
    height: 2rem;
  }
}



.order-card {
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 8px 8px -8px rgb(36 36 36 / 10%);
  .order-status {
    color: black;
    background: #EEF2F6; 
    width: 100%;
    padding: 0.75rem 1.5rem;
    margin: 0;
  }

  .new {
    color: white;
    background: #00ff88;
  }

  .block {
    border-radius: 0;
    border: 0;
  }
}

  
</style>
