<template>
  <div class="photo-container" @wheel="handleWheel" @mousedown="startDrag" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <img loading="lazy" :src="photoUrl" :style="imgStyle" ref="image" @load="initialize" />
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue';

const props = defineProps({
  photoUrl: String,
  show: Boolean,
});

const scale = ref(1);
const position = reactive({ x: 0, y: 0 });
const dragging = ref(false);
const startPosition = reactive({ x: 0, y: 0 });
const startScale = ref(1);
const startDistance = ref(0);

const imgStyle = computed(() => ({
  transform: `scale(${scale.value}) translate(${position.x}px, ${position.y}px)`,
}));

const handleWheel = (event) => {
  event.preventDefault();
  const delta = event.deltaY > 0 ? -0.1 : 0.1;
  scale.value = Math.min(Math.max(1, scale.value + delta), 3);
};

const startDrag = (event) => {
  event.preventDefault();
  dragging.value = true;
  startPosition.x = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX;
  startPosition.y = event.type === 'mousedown' ? event.clientY : event.touches[0].clientY;
  const move = (moveEvent) => {
    if (dragging.value) {
      const currentX = moveEvent.type === 'mousemove' ? moveEvent.clientX : moveEvent.touches[0].clientX;
      const currentY = moveEvent.type === 'mousemove' ? moveEvent.clientY : moveEvent.touches[0].clientY;
      position.x += (currentX - startPosition.x) / scale.value;
      position.y += (currentY - startPosition.y) / scale.value;
      startPosition.x = currentX;
      startPosition.y = currentY;
    }
  };
  const endDrag = () => {
    dragging.value = false;
    document.removeEventListener('mousemove', move);
    document.removeEventListener('mouseup', endDrag);
    document.removeEventListener('touchmove', move);
    document.removeEventListener('touchend', endDrag);
  };
  document.addEventListener('mousemove', move);
  document.addEventListener('mouseup', endDrag);
  document.addEventListener('touchmove', move);
  document.addEventListener('touchend', endDrag);
};

const handleTouchStart = (event) => {
  if (event.touches.length === 2) {
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    const distanceX = Math.abs(touch1.clientX - touch2.clientX);
    const distanceY = Math.abs(touch1.clientY - touch2.clientY);
    startDistance.value = Math.sqrt(distanceX ** 2 + distanceY ** 2);
    startScale.value = scale.value;
  } else {
    startDrag(event);
  }
};

const handleTouchMove = (event) => {
  if (event.touches.length === 2) {
    event.preventDefault();
    const touch1 = event.touches[0];
    const touch2 = event.touches[1];
    const distanceX = Math.abs(touch1.clientX - touch2.clientX);
    const distanceY = Math.abs(touch1.clientY - touch2.clientY);
    const newDistance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
    const scaleFactor = newDistance / startDistance.value;
    scale.value = Math.min(Math.max(1, startScale.value * scaleFactor), 3);
  }
};

const handleTouchEnd = (event) => {
  if (event.touches.length === 0) {
    startDistance.value = 0;
  }
};

const initialize = () => {
  scale.value = 1;
  position.x = 0;
  position.y = 0;
};
</script>

<style>
.photo-container {
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.photo-container img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  transition: transform 0.3s;
}
</style>
