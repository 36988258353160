<template>
	<section id="main-menu" class="pd-thin pos-relative">
		<h2 class="w-m-60r mn-auto pd-t-big pd-b-big t-center">
			Weed and Accessories to <br><b>Enhance Your Smoking Experience</b> 
		</h2>

		<transition name="fade" mode="out-in"  appear>
			<div v-if="!menu"  class="w-100 h-15r radius-big flex-center bg-light">
				<Loader class="pos-relative"/>
			</div>

			<ul v-else class="gap-thin grid-container">
				<router-link :to="getMarketplaceLink([category.url])"
					v-for="(category,index) in categories.state.all.slice(0,5)" 
					:key="category" 
					class="cursor-pointer hover-scale-0 flex-v-center flex-h-center flex-nowrap flex-row flex bg-light radius-big pd-medium grid-item"
				>

					<video 
						v-if="category.url === 'buds'" 
						class="w-33 radius-100 o-hidden mn-r-medium" 
						autoplay loop muted
					>
					  <source 
					  	:src="'/output.webm'" 
					  	type="video/webm"
					  >
					</video>

					<div class="w-100" :class="{'mn-r-auto':category.url === 'buds'}">

						<div class="w-100 flex-v-center flex-nowrap flex">

							<h3 class="t-black capitalize h3">
								{{category.name}}
							</h3>

							<img loading="lazy" 
								v-if="category.url !== 'buds'"
								:src="'/icons/categories/' + category.url + '.svg'"
								class="i-extra t-transp mn-l-auto"
							>
						
						</div>
						
						<p v-if="category.url === 'buds'" class="mn-t-thin mn-b-thin t-transp t-black p-medium">
							Unveiling the Exceptional User Curated Best Buds
						</p>
						
						<button 
							v-if="category.url === 'buds'"
			        class="mn-t-small radius-extra hover-bg-black t-medium t-black bg-main hover-t-white button-small button"
			      >
			        <span>Check {{countProduct.count}} products →</span>
			      </button>

					</div>
				</router-link>

			</ul>
		</transition>
	</section>
</template>


<script setup>
	import { ref, onMounted,computed } from 'vue'
	import Loader from '@pf/src/components/Loader/Loader.vue'

	import { useRouter } from 'vue-router'
	import { useI18n } from 'vue-i18n'
		
	import * as categories from '@pf/src/modules/products/store/categories';
	import * as products from '@pf/src/modules/products/store/products';

	const router = useRouter()
	
 	const text = {
    locale: 'en',
    messages: {
      en: {
      	categories: []
      },
      ru: {
      	categories: []
      }
    }
  }

  categories.state.all.forEach( category => {
    category.localization.forEach( localization => {
      text.messages[localization.locale].categories.push(localization.text)
    })
  })

	const { t } = useI18n(text)

	const countProduct = ref(0)
	const menu = ref(false)

	onMounted( async () => {
		countProduct.value = await products.actions.read({count: true})
		await categories.actions.fetchCategories()
		menu.value = true
	})

</script>

<style lang="scss">
	.grid-container {
	  display: grid;
	  grid-template-columns: repeat(4, 1fr);
	  grid-template-rows: repeat(2, 1fr);
	}

	.grid-item {
	  &:first-of-type {
	  	grid-column: span 2;
	  	grid-row: span 2;
	  }
	}

	@media screen and (max-width: 1025px) {
    .grid-container {  grid-template-columns: repeat(2, 1fr);  }
  }
</style>
