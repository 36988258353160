<template>
	<div class="flex flex-column w-100 o-hidden gap-thin pd-thin">
		<!-- <Breadcrumbs class="bg-light radius-big pd-small"/> -->
		<!-- {{product.quantity}}
		<div v-if="product.ingredients">
			{{product?.ingredients[0]?._id}}
			{{product?.ingredients[0]?.quantity}}
			{{product?.ingredients[1]?.quantity}}
		</div> -->
		<SectionProduct v-if="product" :product="product" :user="auth.state.user" class="pos-relative"/>

		<PopularProducts />
	</div>
</template>

<script setup="props">
	// Import components
	// import Breadcrumbs from '@pf/src/components/Breadcrumbs/Breadcrumbs.vue'
	import PopularProducts 	from '@pf/src/modules/products/components/sections/PopularProducts.vue'
	import SectionProduct from '@pf/src/modules/products/components/sections/SectionProduct.vue'
	// Import libs
	import { computed, onMounted } from 'vue'
	import { useRoute,useRouter } from 'vue-router'
	import { useI18n }    from 'vue-i18n'
	
  import * as auth from '@pf/src/modules/auth/views/store/auth';
 	import * as products from '@pf/src/modules/products/store/products';
  import * as categories from '@pf/src/modules/products/store/categories';

	// Accessing router and store
	const route = useRoute()
	const router = useRouter()

	
	route.meta.title.en = products.state.current.name
	route.meta.title.ru = products.state.current.name

	// Accessing state
	const product = computed(() => products.state.current)

	const text = {
		messages: {
			en: {

			},
			ru: {

			}
		}
	};

	onMounted(async ()=> {
		await products.actions.read({_id: route.params.product});

	})
	
	// products.state.current.localization.forEach(localization => {
  	// if (localization) text.messages[localization.locale].description = localization.text
	// })

  const { t } = useI18n(text)
</script>

<style lang="scss">
.h1-product  {
	font-size: 3rem;
}
.produc-data { 
	padding-top: 2rem;
	padding-bottom: 0;
}
</style>
