<template>
	<div class="cols-2-1_2 w-100 gap-thin">
		
		<div class="pd-small bg-light radius-big flex-nowrap flex-column flex pos-relative">
			<div class="w-100 bg-light radius-semi o-hidden">
				<!-- PRODUCT IMAGE -->
				<Images360
					v-if="product && product.image3d"
					class="h-100 w-100"
					:imagePath="`/assets/images/products/${product.image3d}`"
					:imageCount="36"
				/>

				<img loading="lazy" 
					v-if="images[0] && !product.image3d"
					class="h-auto bg-light w-100"
					style="object-fit: contain;"
					:src="(FILE_SERVER_URL || '') + images[0]"
				/>

				<PlaceholderImage
					v-if="!images[0] && !product.image3d"
					class="h-100 w-100"
					style="object-fit: cover;"
				/>

			</div>

			<ImagesThumbnails
				v-if="images.length > 1"
				:images="images"
				class="mn-t-thin"
			/>
		</div>

		<div class="pd-big bg-light radius-big pos-relative w-100 h-100 flex-column flex-justify flex col">

		<!-- <IconEdit
			@click="$router.push({
				name: 'ProductEdit', 
				params: {
					_id: product.organization,
					product: product._id
				}
			})" 
			class="pos-absolute pos-t-regular pos-r-regular i-regular t-transp"
		/> -->

			<h2 v-if="recommendation" class="t-main t-semi p-big">{{t('airecommend')}}</h2>
			<!-- Name -->
			<h1 class="w-100 h1-product mn-b-thin">{{ product.name }}</h1>
			<!-- Price -->
			<Price :product="product" size="big" class="mn-b-semi" />
			<!-- Modifications -->
			<!-- <div v-if="product.modifications.length > 0" class="flex-nojustify  flex">
				<SelectElement v-if="sizes2.length > 0" :elements="sizes2" :selected="product.selectedSize" class="mn-r-medium" />
			</div> -->
			<!-- Description -->
			<h3 v-if="recommendation" class="mn-b-semi">
				{{ recommendation }}
			</h3>


			<p v-if="product.description && !product.localization < 1 && !recommendation" class="w-100 mn-b-medium t-transp">
				{{ product.description }}
			</p>

			<p v-if="product.localization && product.localization.length > 1 && !recommendation" class="w-100  mn-b-medium t-transp">
				{{ t('description') }}
			</p>

			<div 
				class="cols-2 w-100 gap-small mn-b-semi"
			>
				<div 
					v-if="product.information.length > 0" 
					v-for="information in product.information" 
					class="w-100 pd-small radius-small bg-grey-transp-10 product-information"
				>
					<p class="t-demi">{{ information.name }}</p>
					<p>{{ information.value }}</p>
				</div>
			</div>

			<!-- Actions -->
			<div class="max-w-33rem mobile-w-100 t-white gap-small cols-2">
				<Button
					v-if="product.quantity > 0"
					:submit="a => addToCart(product)" 
					:disabled="validateToCard(product)" 
					:text="{
						success: ' ✔ Added',
						error: 'error'
					}"
					class="h-3r w-100 mn-r-small bg-main button"
				> 
						{{ t('addtoorder') }}
				</Button>
				<div v-else class="flex-center flex uppercase radius-big t-black w-max pd-small t-medium bg-grey h-3r w-100 mn-r-smallbutton">
					Out of Stock
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import Button 				from '@pf/src/components/Button/Button.vue'
import IconEdit from '@pf/src/modules/icons/navigation/IconEdit.vue'
import PlaceholderImage from '@pf/src/modules/icons/placeholders/PlaceholderImage.vue'

import Images360 	from '@pf/src/modules/products/components/blocks/Images360.vue'
import ImagesThumbnails from "@pf/src/modules/products/components/blocks/ImagesThumbnails.vue";

import THC from '@pf/src/modules/products/components/elements/THC.vue'
import Price from '@pf/src/modules/products/components/elements/Price.vue'
// import SelectElement 	from '@/components/elements/SelectElement.vue'

import { computed } from 'vue'
import { useI18n }    from 'vue-i18n'

import * as shopcart from '@pf/src/modules/orders/store/shopcart';

// Props
const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  user: {
    type: String,
  },
  recommendation: {
  	type: String
  }
})

// Computed properties
const images = computed(() => {
  return props.product.images
})

const text = {
	messages: {
	  en: {
	  	airecommend: 'AI Recommends You',
	  	description: '',
	  	addtoorder: 'Add to order',
	    fastorder: 'Fast Order'
	  },
	  ru: {
	  	airecommend: 'AI Рекомендует вам',
	  	description: '',
	  	addtoorder: 'Добавить в корзину',
	    fastorder: 'Быстрый заказ'
	  }
	}
}

// props.product.localization.forEach(localization => {
	// if (localization) text.messages[localization.locale].description = localization.text
// })

 const { t } = useI18n(text)

// Methods
function validateToCard(product) {
  if (product.quantity > 0) {
    return false
  }
  return true
}

function addToCart(product) {
	if (shopcart.state.organization !== product.organization) {
		shopcart.state.positions = []
		shopcart.state.organization = product.organization
	}
  shopcart.actions.addProductToCart(product, product.organization)
}
</script>
