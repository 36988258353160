<template>
  <div class="cols-2 gap-medium pd-medium bg-light radius-big">
    <div class="">
      <h3
        class="mn-b-medium"
      >
        Finished Events
      </h3>
      <List
        :user="auth.state.user._id"
        class="bg-white mn-b-medium radius-big"
        :phase="'finished'"
      />
    </div>

    <div class="">
      <h3
        class="mn-b-medium"
      >
        Planned
      </h3>
      <List
        :user="auth.state.user._id"
        class="bg-white mn-b-medium radius-big"
        :phase="'planned'"
      />
    </div>
  </div>
</template>

<script setup="props">
  import { computed, onMounted, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import List from '@pf/src/modules/events/components/sections/List.vue';   
  // Import state
  import * as events from '@pf/src/modules/events/store/events.js'; 
  import * as auth from '@pf/src/modules/auth/views/store/auth'

  // State
  const route = useRoute();
  const router = useRouter();
</script>

<style lang="scss">
  
</style>
