// Store
import * as storeDepartments from './store/departments.js';
import * as storeMemberships from './store/memberships.js';
import * as storeOrganizations from './store/organizations.js';
import * as storeInvites from './store/invites.js';

// Router
import routerOrganization from './router/organizations';

// Views
// Pages
import Organization from './components/pages/Organization.vue';
import Department from './components/pages/Department.vue';
import OrganizationEdit from './components/pages/OrganizationEdit.vue';
import Organizations from './components/pages/Organizations.vue';
import DepartmentEdit from './components/pages/DepartmentEdit.vue';

// Blocks
import CardOrganization from './components/blocks/CardOrganization.vue';
import EditArray from './components/blocks/EditArray.vue';
import Unit from './components/blocks/Unit.vue';
import DepartmentSub from './components/blocks/DepartmentSub.vue';
import CardDepartment from './components/blocks/CardDepartment.vue';
import Rating from './components/blocks/Rating.vue';
import EmptyState from './components/blocks/EmptyState.vue';
import Contacts from './components/blocks/Contacts.vue';
import Socials from './components/blocks/Socials.vue';

// Sections
import MembersAdd from './components/sections/MembersAdd.vue';
import DetailsTabSection from './components/sections/DetailsTabSection.vue';
import OrganizationsSection from './components/sections/Organizations.vue';
import Publics from './components/sections/Publics.vue';
import Documents from './components/sections/Documents.vue';

// Elements
import ButtonToggleMembership from './components/elements/ButtonToggleMembership.vue';

// Пример функции инициализации для модуля организаций
function initializeOrganization(app, store, router, options = {}) {
  const route = options.route || 'Home';
  
  router.addRoute(route, routerOrganization);
  
  store.addStore('departments', storeDepartments);
  store.addStore('memberships', storeMemberships);
  store.addStore('organizations', storeOrganizations);
  store.addStore('invites', storeInvites);
}

// Export components, store modules, and routes
export {
	Organization,
	Department,
	OrganizationEdit,
	Organizations,
	DepartmentEdit,
	CardOrganization,
	EditArray,
	Unit,
	DepartmentSub,
	CardDepartment,
	Rating,
	EmptyState,
	Contacts,
	Socials,
	MembersAdd,
	DetailsTabSection,
	OrganizationsSection,
	Publics,
	Documents,
	ButtonToggleMembership,
	storeDepartments,
	storeMemberships,
	storeOrganizations,
	storeInvites,
	routerOrganization
};


const ModuleOrganization = {
  initialize: initializeOrganization,
  views: {
    store: {
      storeDepartments,
      storeMemberships,
      storeOrganizations,
      storeInvites
    },
    router: {
      routerOrganization
    },
    components: {
      // Pages
      Organization,
      Department,
      OrganizationEdit,
      Organizations,
      DepartmentEdit,
      // Blocks
      CardOrganization,
      EditArray,
      Unit,
      DepartmentSub,
      CardDepartment,
      Rating,
      EmptyState,
      Contacts,
      Socials,
      // Sections
      MembersAdd,
      DetailsTabSection,
      OrganizationsSection,
      Publics,
      Documents,
      // Elements
      ButtonToggleMembership
    }
  }
}

export default ModuleOrganization;