<template>
  <div class="for-transition pd-thin w-100">

    <header class="mn-b-thin flex-nowrap flex bg-light pd-medium radius-big">
      <h1 v-if="route.params.department" class="mn-r-auto">Edit Department</h1>
      <h1 v-else class="mn-r-auto">Create Department</h1>

      <section class="flex-nowrap flex gap-thin pd-thin">
        <Button v-if="route.params.department"   :submit="onDelete" :callback="redirectToDash" class="bg-red">Delete</Button>
        <Button :submit="onSubmit" :callback="redirectTo" class="bg-main">Save</Button>
      </section>

    </header>
    
    <Block
      title="Profile"
      v-if="departments.state.department.profile"
      class="mn-b-thin"
    >
      <div
        class="cols-2-fit-content"
      >
        <UploadImage 
           v-model:photo="departments.state.department.profile.photo"
          :uploadPath="'organizations/' + departments.state.department.name + '/avatars'"
          class="w-8r aspect-1x1 o-hidden mn-r-small radius-extra" 
        />
        <div class="w-100 flex-child-grow-1 flex-child ">
          <Field 
            v-model:field="departments.state.department.profile.name"     
            label="Name"  
            placeholder="Department Name" 
            class="mn-b-small bg-white radius-small pd-medium"
            :validation="organizationName" 
          />
          <Field 
            v-model:field="departments.state.department.profile.description"     
            label="Description"  
            placeholder="Department description (max 120 symbols)" 
            class="bg-white radius-small pd-medium"
            :validation="organizationName" 
          />  
        </div>
      </div>
    </Block>

    <Block
      title="Team"
      placeholder="No members added yet"
      :actions="[{
        label: '+',
        function: () => openMemberPopup()
      }]"
      class="cols-1 gap-thin mn-b-thin"
    > 
      <CardUser 
        class="h-4r bg-white pd-thin radius-medium w-100" 
        v-for="(member, index) in departments.state.department.members" 
        :key="index" 
        :user="member.user" 
        :photo="member.user.profile.photo"
        :name="member.user.profile.name || member.user.phone || member.user.email || member.user._id"
        :position="member.position" 
        :action="{
          label: {
            is: IconDelete,
            props: { class: 'i-medium', fill: 'rgb(var(--white)' }
          },
          method: () => removeMember(member)
        }"
      />
    </Block>

    <Popup 
      title="Add member" 
      @close-popup="closeMemberPopup" 
      :isPopupOpen="isOpenAddMemberPopup"
      class="bg-white w-max-30r radius-big pd-big"
    >
      <Feed
        :search="{
          placeholder: 'Search customer...',
          class: 'bg-light mn-b-thin'
        }"
        :states="{
          empty: {
            title: 'No Products Found',
            description: 'Currently, there are no such products available.'
          }
        }"
        :store="{
          read: (options) => memberships.actions.read(options),
          state: null
        }"
        :options="{
          target: route.params._id
        }"
        v-slot="{ 
          items 
        }"
        class="bg-light pd-medium w-min-20r w-max-40r radius-big h-max-20r o-scroll"
      >
        <CardUser
          v-for="(user, index) in items" 
          :key="user._id"
          :user="user.user"
          :photo="user.user.profile?.photo"
          :name="user.user.profile?.name || user.user.phone || user.user.email"
          @click="() => { 
            globals.actions.add(departments.state.department.members, { _id: user.user._id, user: user.user, position: 'Member'})
            closeMemberPopup();
          }"
          class="h-4r bg-white pd-thin radius-medium w-100 mn-b-thin"
        />
      </Feed>
    </Popup>
      

    <div class="cols-2 gap-thin">
    
        <div class="bg-light pd-medium o-hidden radius-big">
          <h3 class="mn-b-semi">Department Settings</h3>
          
          <p class="p-medium mn-b-small">Please settings for your department:</p>
          <div class="cols-1 gap-thin">
            <Checkbox 
              label="Hidden department"
              name="hidden"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              @update:radio="updated => departments.state.department.hidden = !departments.state.department.hidden"
              :radio="departments.state.department.hidden"
            />
          </div>
        </div>

        <div class="bg-light pd-medium o-hidden radius-big">
          <h3 class="mn-b-semi">Acessess</h3>
          
          <p class="p-medium mn-b-small">Please select organization accesses for user in department:</p>
          
          <div class="cols-1 gap-thin">
            <h4>Events</h4>
            <Checkbox
              label="Read events"
              name="readProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.events.read"
              @update:radio="updated => (departments.state.department.accesses.events.read = !departments.state.department.accesses.events.read)"
            />
            <Checkbox
              label="Create events"
              name="editProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.events.create"
              @update:radio="updated => (departments.state.department.accesses.events.create = !departments.state.department.accesses.events.create)"
            />
            <Checkbox
              label="Edit events"
              name="editProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.events.edit"
              @update:radio="updated => (departments.state.department.accesses.events.edit = !departments.state.department.accesses.events.edit)"
            />
            <Checkbox
              label="Delete events"
              name="deleteProducts"
              class="w-100 bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.events.delete"
              @update:radio="updated => (departments.state.department.accesses.events.delete = !departments.state.department.accesses.events.delete)"
            />
            <h4>Gallery</h4>
            <Checkbox
              label="Read gallery"
              name="readProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.gallery.read"
              @update:radio="updated => (departments.state.department.accesses.gallery.read = !departments.state.department.accesses.gallery.read)"
            />
            <Checkbox
              label="Create photos"
              name="editProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.gallery.create"
              @update:radio="updated => (departments.state.department.accesses.gallery.create = !departments.state.department.accesses.gallery.create)"
            />
            <Checkbox
              label="Edit gallery"
              name="editProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.gallery.edit"
              @update:radio="updated => (departments.state.department.accesses.gallery.edit = !departments.state.department.accesses.gallery.edit)"
            />
            <Checkbox
              label="Delete gallery"
              name="deleteProducts"
              class="w-100 bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.gallery.delete"
              @update:radio="updated => (departments.state.department.accesses.gallery.delete = !departments.state.department.accesses.gallery.delete)"
            />
            <h4>Products</h4>
            <Checkbox
              label="Read products"
              name="readProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.products.read"
              @update:radio="updated => (departments.state.department.accesses.products.read = !departments.state.department.accesses.products.read)"
            />
            <Checkbox
              label="Edit products"
              name="editProducts"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.products.edit"
              @update:radio="updated => (departments.state.department.accesses.products.edit = !departments.state.department.accesses.products.edit)"
            />
            <Checkbox
              label="Delete products"
              name="deleteProducts"
              class="w-100 bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.products.delete"
              @update:radio="updated => (departments.state.department.accesses.products.delete = !departments.state.department.accesses.products.delete)"
            />

            <h4>Orders</h4>
            <Checkbox
              label="Read orders"
              name="readOrders"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.orders.read"
              @update:radio="updated => (departments.state.department.accesses.orders.read = !departments.state.department.accesses.orders.read)"
            />
            <Checkbox
              label="Confirm orders"
              name="confirmOrders"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.orders.confirm"
              @update:radio="updated => (departments.state.department.accesses.orders.confirm = !departments.state.department.accesses.orders.confirm)"
            />
            <Checkbox
              label="Delete orders"
              name="deleteOrders"
              class="w-100 bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.orders.delete"
              @update:radio="updated => (departments.state.department.accesses.orders.delete = !departments.state.department.accesses.orders.delete)"
            />

            <h4>Departments</h4>
            <Checkbox
              label="Read departments"
              name="readDepartments"  class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.departments.read"
              @update:radio="updated => (departments.state.department.accesses.departments.read = !departments.state.department.accesses.departments.read)"
            />
            <Checkbox
              label="Edit departments"
              name="editDepartments"
              class="w-100 mn-r-small bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.departments.edit"
              @update:radio="updated => (departments.state.department.accesses.departments.edit = !departments.state.department.accesses.departments.edit)"
            />
            <Checkbox
              label="Delete departments"
              name="deleteDepartments"
              class="w-100 bg-white radius-small pd-small"
              :radio="departments.state.department.accesses.departments.delete"
              @update:radio="updated => (departments.state.department.accesses.departments.delete = !departments.state.department.accesses.departments.delete)"
            />
          </div>
        </div>


      </div>

  
    


<!--     <div id="dash" class="pd-medium bg-white">

      <Popup title="Добавить подотдел" @close-popup="closeDepartmentPopup" :isPopupOpen="isOpenDepartmentPopup">
        <DepartmentSubDepartmentModify 
          :departments="departments.state.department.subdepartments" 
          :department="selectedDepartment" 
          :maindepartment="departments.state.department"
          :alldepartments="departments.state.departments"
          @callback="closeDepartmentPopup"
        />
      </Popup>
    
      <div class="mn-b-regular  flex-v-center flex-nowrap flex">
        <h3 class="mn-r-small">Подотделы </h3>
        <div>
          <a  class="t-main h5" @click="openDepartmentPopup()">+</a>
        </div>
      </div>

        <ul class="mn-b-small">
          <li v-if="departments.state.department.subdepartments.length < 1">В отделе еще нет подотделов</li>
          <DepartmentSub  
            v-for="(subdepartment, index) in departments.state.department.subdepartments" 
            :key="index" class="bg-light o-hidden radius-small mn-b-thin" 
            :department="subdepartment" 
              @click="openDepartmentPopup(index)"
            />
        </ul> 

      
    </div> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Cookies from "js-cookie";
import Breadcrumbs from '@pf/src/components/Breadcrumbs/Breadcrumbs.vue'
import UploadImage from '@pf/src/components/UploadImage/UploadImage.vue';

import Tab from "@pf/src/components/Tab/Tab.vue";
import Field from "@pf/src/components/Field/Field.vue";
import Select from "@pf/src/components/Select/Select.vue";
import Checkbox from "@pf/src/components/Checkbox/Checkbox.vue";
import Address from "@pf/src/components/Address/Address.vue";
import LocationMarker from "@pf/src/components/LocationMarker/LocationMarker.vue";
import Button from "@pf/src/components/Button/Button.vue";
import Popup from "@pf/src/components/Popup/Popup.vue";
import Block from '@pf/src/components/Block/Block.vue';
import Feed from '@pf/src/components/Feed/Feed.vue'

import IconDelete from '@pf/src/modules/icons/navigation/IconDelete.vue'

import EditArray from "@pf/src/modules/organizations/components/blocks/EditArray.vue";
import DepartmentSub from "@pf/src/modules/organizations/components/blocks/DepartmentSub.vue";

import CardUser from '@pf/src/modules/auth/views/components/blocks/CardUser.vue'

import * as globals from "@pf/src/modules/globals/views/store/globals";
import * as departments from "@pf/src/modules/organizations/store/departments";
import * as memberships from "@pf/src/modules/organizations/store/memberships";

const router = useRouter();
const route = useRoute();

async function fetchData() {
  const users = ref(memberships.state.memberships);
  // await departments.actions.read({organization: route.params._id});
  if (route.params.department) await departments.actions.readOne(route.params.department);
}

fetchData();

// const members = ref(departments.state.department.members);

const isOpenAddMemberPopup = ref(false);
const selectedMember = ref(null);

function openMemberPopup(member) {
  isOpenAddMemberPopup.value = true;
  if (typeof member === "number") selectedMember.value = member;
}

function closeMemberPopup() {
  isOpenAddMemberPopup.value = false;
  selectedMember.value = null;
}

const isOpenDepartmentPopup = ref(false);
const selectedDepartment = ref(null);

function openDepartmentPopup(department) {
  isOpenDepartmentPopup.value = true;
  if (typeof department === "number") selectedDepartment.value = department;
}

function closeDepartmentPopup() {
  isOpenDepartmentPopup.value = false;
  selectedDepartment.value = null;
}

async function onSubmit() {
  if (route.params.department) {
    await departments.actions.update(
      route.params._id,
      departments.state.department
    );
  }
  if (!route.params.department) {
    await departments.actions.create(
      route.params._id,
      departments.state.department
    );
  }
}

function redirectTo() {
  router.push({ name: "Organization Members", params: { _id: route.params._id } });
}

async function onDelete() {
  if (confirm("Are you sure you want to delete this department?")) {
    await departments.actions.delete(
      route.params._id,
      departments.state.department
    );
  }
  
}

function removeMember(member) {
   if (confirm("Are you sure you want to remove this member?")) {
    globals.actions.delete(departments.state.department.members, { _id: member.user._id}) 
  }
}

function redirectDash() {
  router.replace({ name: "Organization" });
}
</script>

<style scoped>
</style>