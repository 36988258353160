// Store
import * as storeOrders from './store/orders.js';
import * as storeShopcart from './store/shopcart.js';
import * as storeTestimonials from './store/testimonials';  
// Router
import routerOrders from './router/orders.router.js';
// Views
import Orders from './components/pages/Orders.vue';
import Order from './components/pages/Order.vue';
import OrderCreate from './components/pages/OrderCreate.vue';
import OrderCreateBackoffice from './components/pages/OrderCreateBackoffice.vue';
// import Favorites from './components/pages/Favorites.vue';
// Importing blocks components
import CardOrderItem from './components/blocks/CardOrderItem.vue';
import CardOrder from './components/blocks/CardOrder.vue';
import StatusHistory from './components/blocks/StatusHistory.vue';
// Importing sections components
import FormCustomerDetails from './components/sections/FormCustomerDetails.vue';
import FormPayment from './components/sections/FormPayment.vue';
import FormDelivery from './components/sections/FormDelivery.vue';
import EmptyState from './components/sections/EmptyState.vue';
import AskToLogin from './components/sections/AskToLogin.vue';
import Succes from './components/sections/Succes.vue';
// Importing partials components
import ShopCart from './components/partials/ShopCart.vue';

// Пример функции инициализации для модуля заказов
function initializeOrders(app, store, router, options = {}) {
  const route = options.route || 'Home';
  
  router.addRoute(route, routerOrders);
  store.addStore('orders', storeOrders);
  store.addStore('shopcart', storeShopcart);
  store.addStore('testimonials', storeTestimonials);
}

const ModuleOrder = {
  initialize: initializeOrders,
  views: {
    store: {
      storeOrders,
      storeShopcart,
      storeTestimonials
    },
    router: {
      routerOrders
    },
    components: {
      // Pages
      Orders,
      Order,
      OrderCreate,
      OrderCreateBackoffice,
      // Favorites,
      // Blocks
      CardOrderItem,
      CardOrder,
      StatusHistory,
      // Sections
      FormCustomerDetails,
      AskToLogin,
      FormDelivery,
      EmptyState,
      Succes,
      FormPayment,
      // Partials
      ShopCart,
    }
  }
}

export default ModuleOrder
