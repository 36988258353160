<template>
  <section class="pd-thin">
    <header 
      class="mn-b-medium flex-v-center flex-nowrap flex"
    >
      <h2
        class="mn-r-medium"
      >
        Featured
      </h2>
    </header>
    
    <List
      :user="auth.state.user._id"
      class="bg-light mn-b-medium radius-big"
      :phase="'planned'"
      :status="'featured'"
    />

    <h2
      class="mn-b-medium"
    >
      All Events
    </h2>

    <DatePicker
      v-model:date="events.state.filter.date"
      :locale="$i18n.locale"
      class="radius-semi o-hidden bg-light mn-b-thin pd-l-zero pd-r-zero pd-medium"
    />

    <Feed
      :states="{
        empty: {
          title: 'No Events Found',
          description: 'Currently, there are no events available.'
        }
      }"
      :store="{
        read: (options) => events.read(options),
        state: events.state
      }"
      :options="{
          user: auth.state.user._id,
          date: events.state.filter.date,
          limit: 10
      }"
      v-slot="{ 
        items 
      }"
    >
       <CardEvent 
        @click="$router.push({name: 'Event', params: {url: event.url}})" 
        v-for="(event,index) in items" 
        :key="event._id" 
        :event="event" 
        :user="auth.state.user._id" 
        :type="'normal'"
        class="bg-light radius-big mn-b-medium"
      >
      </CardEvent>
    </Feed>
  </section>
</template>

<script setup="props">
  import { computed, onMounted, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import Breadcrumbs from '@pf/src/components/Breadcrumbs/Breadcrumbs.vue';
  import Feed from '@pf/src/components/Feed/Feed.vue'
  import DatePicker from '@pf/src/components/DatePicker/DatePicker.vue'


  import List from '@pf/src/modules/events/components/sections/List.vue';   
  import CardEvent from '@pf/src/modules/events/components/blocks/CardEvent.vue';
  // Import state
  import * as events from '@pf/src/modules/events/store/events.js'; 
  import * as auth from '@pf/src/modules/auth/views/store/auth'

  // State
  const route = useRoute();
  const router = useRouter();

  let baseDate = new Date();
  const formattedDate = formatDateForRouter(baseDate);
  
  function formatDateForRouter(date) {
    return `${date.getDate().toString().padStart(2, '0')}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getFullYear()}`;
  }

  if (route.query) {
    const query = route.query;
    
    const newFilterValue = {
      tags: query.tags ? query.tags.split(',') : null,
      date: query.date ? query.date : formattedDate,
    };

    events.state.filter = newFilterValue;  // <-- Modified
  } else {
    const newFilterValue = {
      date: formattedDate,
    };

    events.state.filter = newFilterValue;
  }

  watch(() => events.state.filter, (newFilterValue, oldFilterValue) => {  // <-- Modified
    const query = { ...route.query };

    Object.keys(oldFilterValue).forEach(key => {
      if (query[key]) {
        delete query[key];
      }
    });

    const newQueryValues = Object.fromEntries(
      Object.entries(newFilterValue)
        .filter(([key, value]) => (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value))
        .map(([key, value]) => [key, Array.isArray(value) ? value.join(',') : value])
    );
    Object.assign(query, newQueryValues);

    router.push({ query });
  }, { deep: true })
</script>

<style lang="scss">
  
</style>
