<template>
	<div class="pd-thin">
		<header class="mn-b-medium flex-v-center flex-nowrap flex">
	      	<h2 class="mn-r-medium">Groups</h2>
	      	<button 
	      		@click="$router.push({
	      			name: 'Create Organization'
	      		})" 
	      		class="
	      			bg-second
	      			radius-100 
	      			i-big 
	      			hover-scale-1
	      			cursor-pointer
	      			t-white
	      			bg-second
	      		">
	      			+
	      	</button>
		</header>

		<Tab 
			v-model:selected="tabOrganization"
			:tabs="[
				{ name: 'Owner', 		value: 'owner' },
				{ name: 'Member', 	value: 'member' },
				{ name: 'Follower', value: 'follower' }		
			]"
			class="
				mn-b-medium 
				o-hidden 
				h5 
				radius-big 
				bg-light
			"
		/>

		<transition name="slide-fade">
			<Feed 
				v-if="tabOrganization === 'owner'"
		  	:user="auth.state.user._id"
				:owner="route.params._id"
			/>
		</transition>

		<transition name="slide-fade">
			<Feed 
				v-if="tabOrganization === 'member'"
		  	:user="auth.state.user._id"
				:member="route.params._id"
			/>
		</transition>

		<transition name="slide-fade">
			<Feed 
				v-if="tabOrganization === 'follower'"
		  		:user="auth.state.user._id"
		  		:subscriber="route.params._id"
			/>
		</transition>
	</div>
</template>

<script setup>
	import { watch, ref } from 'vue'
	import { useRoute, useRouter } from 'vue-router'
	// Components
	import Tab  		from '@pf/src/components/Tab/Tab.vue'
	import Feed from '@pf/src/modules/organizations/components/sections/Feed.vue'; 

	import * as auth from '@pf/src/modules/auth/views/store/auth';
	// Accessing router
	const route = useRoute()
	const router = useRouter()
	// State
	let tab = route.query.tab ? route.query.tab : 'owner';

	const tabOrganization = ref(tab)

	route.query.tab = tabOrganization.value

	watch(tabOrganization, (newValue) => {
	  router.replace({ query: { ...route.query, tab: newValue } });
	});
</script>