<template>
  <div>
    <div 
      class="w-100 scroller o-scroll scroll-snap-type-x-mandatory scroll-pd-regular scroll-hide flex-nowrap flex" 
      ref="scrollContainer"
    >
      <div
        v-for="date in dates"
        :key="date.toString()"
        @click="selectDate(date)"
        :class="{
          'selected': selectedDate.toDateString() === date.toDateString(),

        }"
         class="cursor-pointer scroll-snap-align-start mn-r-small mn-l-small flex-column flex-center flex"
      >
        <span 
          class="t-transp"
        >
          {{ dayLetter(date) }}
        </span>
        <span 
          :class="{
           'bg-main': selectedDate.toDateString() === date.toDateString(),
          }"
          class="transition-ease-in-out t-semi flex-center flex radius-big i-semi day-number">
          {{ date.getDate() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';

const emit = defineEmits();
const props = defineProps(['date','locale']); // замена 'initialDate' на 'date'

const scrollContainer = ref(null);
let baseDate;

// Проверка и преобразование даты из формата строки в объект Date
if (props.date) {
  const day = parseInt(props.date.substring(0, 2));
  const month = parseInt(props.date.substring(2, 4)) - 1; // месяцы начинаются с 0
  const year = parseInt(props.date.substring(4));
  baseDate = new Date(year, month, day);
  console.log('Data' + baseDate)
} else {
  baseDate = new Date();
  console.log('No data' + baseDate)
}

baseDate.setHours(0, 0, 0, 0);  // Normalize time to compare just the date

const selectedDate = ref(new Date(baseDate)); // Using new Date to create a copy
const dates = generateDates(baseDate, 30); // 30 дней

onMounted(() => {
  if (scrollContainer.value) {
    const target = scrollContainer.value.getElementsByClassName('selected')[0] || scrollContainer.value.getElementsByClassName('bg-main')[0];
    if (target) {
      scrollContainer.value.scrollLeft =
        target.offsetLeft - scrollContainer.value.offsetWidth / 2 + target.offsetWidth / 2;
    }
  }
});

function selectDate(date) {
  selectedDate.value = date;
  const formattedDate = formatDateForRouter(date);
  emit('update:date', formattedDate);
}


function formatDateForRouter(date) {
  return `${date.getDate().toString().padStart(2, '0')}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getFullYear()}`;
}



function dayLetter(date) {
  return date.toLocaleDateString(props.locale ? props.locale : 'en', { weekday: 'narrow' }).toUpperCase();
}

function generateDates(start, count) {
  const dates = [];
  for (let i = -15; i < count - 15; i++) {
    const newDate = new Date(start);
    newDate.setDate(start.getDate() + i);
    newDate.setHours(0, 0, 0, 0);  // Normalize time to compare just the date
    dates.push(newDate);
  }
  return dates;
}
</script>

<style lang='scss'>
</style>
