<template>
  <section 
  	class="
	  	t-white
	  	t-center
	  	w-100 
	  	pos-relative
	  	gap-thin
	  	cols-2
	  	pos-relative
	  "
	 >
   	<div 
   		v-if="content.cover || content.video"
   		style="min-height: 20rem;"
   		class="desktop-only radius-big o-hidden bg-black  pos-relative"
   	>

	    <img loading="lazy"  v-if="content.cover && !content.video" :src="content.cover" 	class="desktop-only object-fit-cover pos-absolute z-index-0 pos-t-0 pos-l-0 w-100 h-100">

			<video 
				v-if="content.video"
				style="object-fit: cover;" 
				ref="videoElement"  
				class="pos-absolute pos-t-0 pos-l-0 w-100 h-100" 
				preload autoplay muted loop playsinlineclass playsinline
				itemprop="video" 
				type="video/webm"
			>
				 <source :src="content.video" :type="getVideoType(content.video)" />
			</video>
	  </div>

   	<div 
   		class="
   		content-hero-refactor
   		radius-big 
	  	o-hidden 
	  	bg-black  
	  	pd-big mn-r-auto mn-l-auto t-white flex-h-center flex-column o-hidden flex-v-start t-left flex w-100 h-100 gap-zero">
    	<div v-if="content.logos.length > 0" class="mn-b-big z-index-1 flex-nowrap flex gap-small">
	    	<img loading="lazy" v-for="logo in content.logos" class="radius-thin object-fit-contain i-big" :src="logo">
	    </div>

    	<h3 v-if="content?.subtitle" class="pd-thin z-index-1  w-max br-solid br-2px br-white radius-extra 	mn-b-thin">
					{{content?.subtitle}}
	    </h3>

    	<h1 class="z-index-1  t-main mn-b-semi">
					{{content?.title}}
	    </h1>

	    <p class="z-index-1  p-big mn-b-semi">
	    		{{content?.description}}
	    </p>
	  	
	  	<p v-if="timeDifference >= 0" class="z-index-1 t-transp p-small mn-b-thin">
				<span>
					{{content?.ticketText || 'Buy ticket online with special price' }}
				</span>

				<span v-if="options?.price" class="mn-l-micro bg-white t-black pd-t-nano pd-b-nano pd-r-micro pd-l-micro radius-extra">
					{{options?.price}} {{returnCurrency()}}
				</span>
	    </p>
	    


	    <Countdown 
	    	v-if="options.date"
	    	class="z-index-1 mn-t-medium w-100 w-max-30r"
	    	:date="options.date"
	    />

	    <div v-if="content?.ticketLinkStripe && timeDifference >= 0" class="z-index-1  mn-t-medium mn-b-small 	flex-nowrap flex gap-thin">
	    	<a href="#" @click="clickBuyTicket()" class="pd-l-medium pd-r-medium pd-t-small pd-b-small radius-extra uppercase t-medium t-black bg-white  ">
	    			{{content?.ticketTextButton || 'Buy Ticket' }}
	    	</a>
	    </div>

	    <img loading="lazy" style="object-fit: cover; opacity: 0.2;"  v-if="content.cover && !content.video" :src="content.cover" 	class="mobile-only pos-absolute z-index-0 pos-t-0 pos-l-0 w-100 h-100">

	    <video 
		    v-if="content.video"
				style="object-fit: cover; opacity: 0.2;" 
				ref="videoElement"  
				class="mobile-only pos-absolute z-index-0 pos-t-0 pos-l-0 w-100 h-100" 
				preload autoplay muted loop playsinlineclass playsinline :src="content.video" 
				itemprop="video" 
				type="video/webm"
			>
			</video>
	  
    </div>


  </section>
</template>


<script setup>
	import { ref, onMounted } from 'vue'
	import { useI18n } from 'vue-i18n'

	import Countdown from "@pf/src/components/Countdown/Countdown.vue"

	const props = defineProps({
		content: {
			type: Object
		},
		options: {
			type: Object
		}
	})


	const videoElement = ref(null)

	var isPlaying = false;

	const targetDate = new Date(props.options.date).getTime();
	const currentDate = ref(new Date().getTime());
	const timeDifference = targetDate - currentDate.value;

	function checkAndPlayVideo() {
		if (videoElement.value) videoElement.value.onplaying = function() { isPlaying = true; };
		if (videoElement.value) videoElement.value.onpause = function() { isPlaying = false; };	
  }

  function getVideoType(videoUrl) {
    if (videoUrl.endsWith('.mp4')) {
      return 'video/mp4';
    } else if (videoUrl.endsWith('.webm')) {
      return 'video/webm';
    }
    return '';
  }

	async function playVid() {      
    if (videoElement.value?.paused && !isPlaying) {
      return videoElement.value.play();
    }
	} 

	onMounted(() => {
			checkAndPlayVideo()
			playVid()
	})
	
	function clickBuyTicket () {
		gtag('event', 'buy_ticket', {
	    'event_category': 'conversion',
	    'event_label': 'Buy Ticket'
	  });

	  window.open(props.content?.ticketLinkStripe);

	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 1025px) {
		.content-hero-refactor {
			text-align: center;
		}

		h3, div {
			margin-left: auto;
			margin-right: auto;
		}
	}

</style>
