import { defineAsyncComponent } from 'vue'
import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';

import { scrollBehavior, layoutClient, Header } from '@pf/src/modules/globals/globals.client';

import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

import Navigation from '@pf/src/modules/globals/views/components/partials/Navigation.vue'
import Sidebar from '@pf/src/modules/globals/views/components/partials/Sidebar.vue'

import Logotype from '@pf/src/modules/icons/logos/Logotype.vue'

let Walkthrough;

if (__MOBILE_APP__) {
  Walkthrough = defineAsyncComponent(() =>
    import('@pf/src/modules/globals/views/components/sections/Walkthrough.vue').then(_ => _.default)
  );
} else {
  Walkthrough = null;
}

const isServer = typeof window === 'undefined';

let history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [{
  path: '/:locale?',

  components: {
    default: layoutClient
  },

  // beforeEnter: [
  //   validationAuth.initUser
  // ],

  name: 'Home',

  meta: {
    navigation: Navigation,
    sidebar: Sidebar,
    
    header: {
      component: Header,
      logotype: Logotype,
      location: true
    },
    walkthrough: Walkthrough,

  },
  
  children: [{
    path: '',
    name: "Home Page",
    meta: {
      title_hide: true,
      header_theme: 'dark',
      breadcrumbs: {
        hide: true
      },
    },
    component: () => import(/* webpackChunkName: 'Home' */ '../components/pages/Home.vue'),
  },
  {
    path: 'governance',
    name: 'Governance',
    meta: {
      title: {
        en: 'Governance',
        ru: 'Управления'
      },
      header_theme: 'dark',
    },
    component: () => import(/* webpackChunkName: 'Governance' */ '../components/pages/Governance.vue'),
  },
  {
    path: 'farming',
    name: 'Farming',
    meta: {
      title: {
        en: 'Governance',
        ru: 'Управления'
      }
    },
    component: () => import(/* webpackChunkName: 'Governance' */ '../components/pages/Farming.vue'),
  },
  {
    path: 'legal',
    name: 'Legal',
    meta: {
      title: {
        en: 'Legal',
        ru: 'Юридическая информация'
      },
    },
    component: () => import(/* webpackChunkName: 'Legal' */ '../components/pages/Legal.vue'),
  },
  {
    path: 'legal/privacy-policy',
    name: 'Privacy Policy',
    meta: {
      title: {
        en: 'Privacy Policy',
        ru: 'Политика конфиденциальности'
      },
    },
    component: () => import(/* webpackChunkName: 'Privacy' */ '../components/pages/legal/Privacy.vue'),
  },
  {
    path: 'legal/terms-of-use',
    name: 'Terms of Use',
    meta: {
      title: {
        en: 'Terms of Use',
        ru: 'Условия использования'
      },
    },
    component: () => import(/* webpackChunkName: 'Terms' */ '../components/pages/legal/Terms.vue'),
  },
  {
    path: 'legal/cookies-policy',
    name: 'Cookie Policy',
    meta: {
      title: {
        en: 'Cookie Policy',
        ru: 'Политика Сookies'
      },
    },
    component: () => import(/* webpackChunkName: 'Cookies' */ '../components/pages/legal/Cookies.vue'),
  },
  {
    path: 'legal/eula',
    name: 'EULA',
    meta: {
      title: {
        en: 'EULA',
        ru: 'EULA'
      },
    },
    component: () => import(/* webpackChunkName: 'Cookies' */ '../components/pages/legal/EULA.vue'),
  }
  ]
}];

export function _createRouter() {
  return createRouter({
    routes,
    history,
    scrollBehavior
  });
}