// Store
import * as storeBlogposts from './store/blogposts.js';
import * as storeReactions from './store/reactions.js';

// Router
import routerCommunity from './router/blogposts';

// Views
// Layouts
import Community from './components/layouts/Community.vue';

// Pages
import CreateBlogPost from './components/pages/CreateBlogPost.vue';
import BlogPost from './components/pages/BlogPost.vue';
import Blog from './components/pages/Blog.vue';

// Blocks
import FooterBlogpost from './components/blocks/FooterBlogpost.vue';
import Activity from './components/blocks/Activity.vue';
import CardBlogpost from './components/blocks/CardBlogpost.vue';

// Sections
import JoinUs from './components/sections/JoinUs.vue';
import Comments from './components/sections/Comments.vue';
import HotPosts from './components/sections/HotPosts.vue';

// Пример функции инициализации для модуля сообщества
function initializeCommunity(app, store, router, options = {}) {
  const route = options.route || 'Home';

  router.addRoute(route, routerCommunity);
  
  store.addStore('blogposts', storeBlogposts);
  store.addStore('reactions', storeReactions);
}

const ModuleCommunity = {
  initialize: initializeCommunity,
  views: {
    store: {
      storeBlogposts,
      storeReactions
    },
    router: {
      routerCommunity
    },
    components: {
      // Layouts
      Community,
      // Pages
      CreateBlogPost,
      BlogPost,
      Blog,
      // Blocks
      FooterBlogpost,
      Activity,
      CardBlogpost,
      // Sections
      JoinUs,
      Comments,
      HotPosts
    }
  }
}

export {
  Community,
  CreateBlogPost,
  BlogPost,
  Blog,
  FooterBlogpost,
  Activity,
  CardBlogpost,
  JoinUs,
  Comments,
  HotPosts,
  storeBlogposts,
  storeReactions,
  routerCommunity
};

export default ModuleCommunity;