<template>
	<Feed
		v-model:sort="marketplace.state.sort"
		v-model:filter="marketplace.state.filter"
    :search="true"
    :showLoadMore="false"
    :states="{
      empty: {
        title: 'No Shops Found',
        description: 'Currently, there are no shops.'
      },
    }"
    :store="{
      read: (options) => organization.actions.read(options)
    }"
    :options="{
      country: route.params.country,
	    state: route.params.state,
	    city: route.params.city,
	    categories: route.query.categories,
	    prices: route.query.prices,
	    delivery: route.query.delivery,
	    location: globals.state.position?.location,
	    contain: ['products'],
    }"
    v-slot="{ 
      items 
    }"
    class="rows-1 gap-thin"
  >
    <CardOrganization 
	    v-for="organization in items" 
	    :key="organization._id"
	    :organization="organization"
	    :showRating="true"
	    :showFollowers="false"
	    :showProducts="true"
	    class="bg-light w-100 o-hidden radius-big pd-small "
	  />
  </Feed>
</template>

<script setup>
	import { computed,reactive,ref, onMounted,watch  } from 'vue'
	import { useRoute } from 'vue-router'
	
	import * as organization from '@pf/src/modules/organizations/store/organizations'
	import * as marketplace from '@pf/src/modules/products/store/marketplace';
	import * as globals from '@pf/src/modules/globals/views/store/globals'

	import Feed from '@pf/src/components/Feed/Feed.vue'

	import CardOrganization from '@pf/src/modules/organizations/components/blocks/CardOrganization.vue'
	
	const route = useRoute()
</script>

<style lang="scss">
</style>
