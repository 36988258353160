<template>
	<div class="">
		<h1 class="pd-medium radius-medium bg-light mn-b-small">Welcome to Backoffice</h1>
		
		
	</div>
</template>

<script setup="props">
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { ref, onMounted } from 'vue';

// Mobile Module
import Menu from '@pf/src/components/Menu/Menu.vue'
import MenuItem from '@pf/src/components/Menu/MenuItem.vue'
import SelectMulti    from '@pf/src/components/SelectMulti/SelectMulti.vue'

import IconProducts from '@pf/src/modules/icons/entities/IconProducts.vue'
import IconEvents from '@pf/src/modules/icons/entities/IconEvents.vue'
import IconGroups from '@pf/src/modules/icons/entities/IconGroups.vue'
import IconCommunity from '@pf/src/modules/icons/entities/IconCommunity.vue'
import IconGallery from '@pf/src/modules/icons/entities/IconGallery.vue'
import IconOrders from '@pf/src/modules/icons/entities/IconOrders.vue'
import IconLeftovers from '@pf/src/modules/icons/entities/IconLeftovers.vue'
/////////////////////////////
// HELPERS
/////////////////////////////
import { useRoute, useRouter } from 'vue-router'

import * as auth  from '@pf/src/modules/auth/views/store/auth'
import * as organizations  from '@pf/src/modules/organizations/store/organizations'

const route = useRoute()
const router = useRouter()
/////////////////////////////
// CREATED
/////////////////////////////
const props = defineProps({
  favorites: Array,
});

const selectedOrganization = ref(null);
const publics = ref([]);
/////////////////////////////
// MOUNTED
/////////////////////////////
onMounted(async () =>{
})
</script>

<style lang="scss">
</style>