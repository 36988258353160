// Store
import * as storeEvents from './store/events.js';

// Router
import { createEventRoutes } from './router/events';

// Views
import CardEvent from './components/blocks/CardEvent.vue';
import Feed from './components/sections/Feed.vue';
import List from './components/sections/List.vue';
import FeaturedEvents from './components/sections/FeaturedEvents.vue';
import EventsLayout from './components/layouts/layoutEvents.vue';
import EditEvent from './components/pages/EditEvent.vue';
import EventPage from './components/pages/Event.vue';
import EventsPage from './components/pages/Events.vue';

// Пример функции инициализации для модуля событий
function initializeEvents(app, store, router, options = {}) {
  const route = options.route || 'Home';

  const routesEvents = createEventRoutes();
  
  router.addRoute(route, routesEvents);
  
  store.addStore('events', storeEvents);
}

const ModuleEvents = {
  initialize: initializeEvents,
  views: {
    store: {
      storeEvents
    },
    router: {
      createEventRoutes
    },
    components: {
      // Blocks
      CardEvent,
      // Sections
      Feed,
      List,
      FeaturedEvents,
      // Layouts
      EventsLayout,
      // Pages
      EditEvent,
      EventPage,
      EventsPage
    }
  }
}

export default ModuleEvents;
