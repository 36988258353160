<template>
	<div v-if="organizationData" class="pd-thin for-transition w-100">

		<!-- <Breadcrumbs class="mn-b-thin pd-medium bg-light radius-big"/> -->
		<!-- <pre>{{organization.state.current}}</pre> -->

		<DetailsTabSection 	
			:organization="organization.state.current" 
			:user="auth.state.user"
			class="mn-b-thin"
		>

		</DetailsTabSection>

		<Tab 
			v-model:selected="tabOrganization"
			:tabs="[
				{ name: 'Community', value: 'community' },
				{ name: 'Menu', value: 'products' },	
				{ name: 'Events', value: 'events' },
				{ name: 'Spots', value: 'spots' }	
			]"
			class="mn-b-thin o-hidden h5 radius-small bg-light"
		/>

		<div class="pos-relative">

			<transition name="slide-fade">
		    <Feed
		    	v-if="tabOrganization === 'community'"
			    :showLoadMore="false"
			    :states="{
			      empty: {
			        title: 'No Blog Posts Found',
			        description: 'Currently, there are no posts available in this blog. Please check back later.'
			      }
			    }"
			    :store="{
			      read: (options) => blog.read(options)
			    }"
			    :options="{
			      status: 'published',
			      user: auth.state.user._id,
			      owner: route.params._id,
			    }"
			    v-slot="{ 
			      items 
			    }"
			    class="row-1 gap-thin"
			  >
			    <CardBlogpost 
			      v-for="item in items" 
			      :key="item._id" 
			      :blogpost="item" 
			      :user="auth.state.user._id" 
			      class="h-max-40r mn-b-medium"
			    />
			  </Feed>
			</transition>

	
			<transition name="slide-fade">
				<FeedEvents 
					v-if="tabOrganization === 'events'"
			  	:user="auth.state.user._id"
					:owner="route.params._id"
					:sortOrder="'desc'"
					:sortParam="'date.start'"
				/>
			</transition>

			<Block
				v-if="tabOrganization === 'products' && auth.state.user._id === organization.state.current.owner"
				class="flex-nowrap	 gap-thin mn-b-small flex"
			>

				<router-link 
					class="bg-black t-white uppercase t-semi pd-thin radius-extra "
					:to="{
						name:'ProductAdd', 
						params: {
							_id: route.params._id
						}
					}"
					>
					Add Product
				</router-link>

				<router-link 
					class=" uppercase t-semi  pd-thin radius-extra "
					:to="{
						name:'Leftovers', 
						params: {
							_id: route.params._id
						}
					}"
					>
					Change Invetory
				</router-link>

				
				<router-link 
					class="d-block mn-l-auto uppercase t-semi bg-white pd-thin radius-extra "
					:to="{
						name:'Backoffice', 
						params: {
							_id: route.params._id
						}
					}"
					>
					Go to Backoffice
				</router-link>

			</Block>

			<transition name="slide-fade">
				<Products 
					v-if="tabOrganization === 'products'"
					:organization="organization.state.current"
				/>

			</transition>

			<transition name="slide-fade">
				<div v-if="tabOrganization === 'spots'">
					<Feed
			      :states="{
			        empty: {
			          title: 'No Spots Found',
			          description: 'Currently, there are no spots available.'
			        }
			      }"
			      :store="{
			        read: (options) => spots.actions.read(route.params._id),
			        state: organization.state
			      }"
			      :options="{
			        	user: auth.state.user._id,
								sort: 'numberOfMemberships',
								contain: ['blogposts'],
								limit: 10
			      }"
			      v-slot="{ 
			        items 
			      }"
			    >
			    	<CardSpot
		          v-for="(spot, index) in items"
		          :key="index"
		          :spot="spot"
		          :organization="organization.state.current"
		          class="radius-big bg-light mn-b-thin"
		        />
			    </Feed>

			    <button
	          v-if="auth.state.user._id === organization.state.current.owner"
	          @click="$router.push(`/spots/${organization.state.current._id}/spots/create`)"
	          class="mn-b-thin bg-main button w-100"
	        >
	          Add spot
	        </button>

				</div>
			</transition>

		</div>
	</div>
</template>

<script setup>
	import { computed, reactive, ref, onMounted, watch  } from 'vue'
	import { useRoute, useRouter } from 'vue-router'
	// Import components
	import Breadcrumbs 	from '@pf/src/components/Breadcrumbs/Breadcrumbs.vue'
	import Tab  		from '@pf/src/components/Tab/Tab.vue'
	import Block  		from '@pf/src/components/Block/Block.vue'
	import Feed from '@pf/src/components/Feed/Feed.vue'
	// Community
	  import CardBlogpost from '@pf/src/modules/community/components/blocks/CardBlogpost.vue';
	// Mobile Module
	import Menu from '@pf/src/components/Menu/Menu.vue'
	import MenuItem from '@pf/src/components/Menu/MenuItem.vue'
	// Organizations
	import DepartmentSub from '@pf/src/modules/organizations/components/blocks/DepartmentSub.vue';
	import DetailsTabSection from '@pf/src/modules/organizations/components/sections/DetailsTabSection.vue'
	import User from '@pf/src/modules/auth/views/components/blocks/CardUser.vue';
	// Feeds
	import FeedEvents from '@pf/src/modules/events/components/sections/Feed.vue'; 
	// Spots
	import CardSpot from '@pf/src/modules/spots/components/blocks/CardSpot.vue';	
	// Local
	import Products from '@pf/src/modules/products/components/pages/Products.vue';
	// Import state
	import * as auth from '@pf/src/modules/auth/views/store/auth';
	import * as organization from '@pf/src/modules/organizations/store/organizations';
	import * as memberships from '@pf/src/modules/organizations/store/memberships';
	import * as departments from '@pf/src/modules/organizations/store/departments';
	// Community
  import * as blog from '@pf/src/modules/community/store/blogposts.js';
	// Spots
	import * as spots from '@pf/src/modules/spots/store/spots';
	// Init router
	const router 	= useRouter()
	const route 	= useRoute()
	// Accesing state
	let tab = route.query.tab ? route.query.tab : 'products';

	const organizationData = ref(null)
	const tabOrganization = ref(tab)
	// route.query.tab = tabOrganization.value

	onMounted(async () => {
		
		organizationData.value = await organization.actions.read({
			_id: route.params._id, 
			user: auth.state.user._id
		})

 		await memberships.actions.read({target: route.params._id})
	})




	// watch(tabOrganization, (newValue) => {
	//   router.query.tab = newValue
	// });
</script>

<style lang="scss">
	.slide-fade-enter-active {
		// min-height: 100vh;
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.slide-fade-leave-active {
		// min-height: 100vh;
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		min-height: 0;
		position: absolute;
	  transform: translateX(20px);
	  opacity: 0;
	  left: 0;
	  top: 0;
	}

</style>
